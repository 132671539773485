import classes from "./index.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import pen from "../../assets/pen.svg";
import { apiLink } from "../../api";
import axios from "axios";
const Menu = () => {
  const navigate = useNavigate();
  const handleLogOut = () => {
    const token = localStorage.getItem("token");
    console.log(token);
    const headers = { Authorization: `Bearer ${token}` };
    axios.post(`${apiLink}/api/logout`, { headers }).then((response) => {
      console.log(response);
    });
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <section className="menu">
      <div className="top">
         <NavLink to={"edit-information"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.255"
            height="16.697"
            viewBox="0 0 17.255 16.697"
          >
            <g id="Icon" transform="translate(0.5 0.5)">
              <path
                id="_563d7416-6c8a-46ea-a67d-d9c7d3ef6a39"
                data-name="563d7416-6c8a-46ea-a67d-d9c7d3ef6a39"
                d="M13.333,22.222H21.82"
                transform="translate(-5.566 -6.525)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="_69a71041-e827-4b6a-83c2-dc488024d05f"
                data-name="69a71041-e827-4b6a-83c2-dc488024d05f"
                d="M15.5,3.768a1.888,1.888,0,0,1,2.7,0,1.97,1.97,0,0,1,0,2.75L6.937,17.979l-3.6.917.9-3.667Z"
                transform="translate(-3.333 -3.198)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
          </svg>
          Edit information
        </NavLink>
        <br />
        <NavLink to={"change-password"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20.374"
            viewBox="0 0 21 20.374"
          >
            <path
              id="_4305ee07-0a11-49ce-adbc-903052a08dee"
              data-name="4305ee07-0a11-49ce-adbc-903052a08dee"
              d="M20.3,1.3l-2,2.018M10.691,11a5.584,5.584,0,0,1,0,7.847,5.467,5.467,0,0,1-7.778,0,5.582,5.582,0,0,1,0-7.847,5.467,5.467,0,0,1,7.778,0Zm0,0L14.8,6.848m0,0,3,3.026,3.5-3.531-3-3.026M14.8,6.848l3.5-3.531"
              transform="translate(-0.8 -0.593)"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </svg>
          Change Password
        </NavLink>
        <br />
        <NavLink to={"address"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="23"
            viewBox="0 0 21 23"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_1555"
                  data-name="Rectangle 1555"
                  width="21"
                  height="23"
                  transform="translate(-1848 8093.169)"
                  strokeWidth="1"
                />
              </clipPath>
            </defs>
            <g
              id="Mask_Group_18"
              data-name="Mask Group 18"
              transform="translate(1848 -8093.169)"
              clipPath="url(#clip-path)"
            >
              <g
                id="svgexport-6_7_"
                data-name="svgexport-6 (7)"
                transform="translate(-1844.568 8094)"
              >
                <g
                  id="Group_8872"
                  data-name="Group 8872"
                  transform="translate(0 0)"
                >
                  <path
                    id="Path_57106"
                    data-name="Path 57106"
                    d="M11.735,20.077a.313.313,0,0,0,.246.126h0a.312.312,0,0,0,.245-.12c.258-.329,6.317-8.116,6.317-13.048a6.562,6.562,0,0,0-13.124,0c0,1.889,1.066,4.662,3.168,8.243C10.145,17.924,11.719,20.056,11.735,20.077ZM11.986,1.1a5.945,5.945,0,0,1,5.938,5.939c0,4.164-4.771,10.784-5.932,12.332-1.147-1.608-5.945-8.557-5.945-12.332A5.945,5.945,0,0,1,11.986,1.1Z"
                    transform="translate(-4.705 -0.473)"
                    strokeWidth="0.3"
                  />
                  <path
                    id="Path_57107"
                    data-name="Path 57107"
                    d="M16.093,7.371a3.772,3.772,0,1,0-3.772,3.772A3.772,3.772,0,0,0,16.093,7.371Zm-3.772,3.148a3.149,3.149,0,1,1,3.149-3.148,3.149,3.149,0,0,1-3.149,3.148Z"
                    transform="translate(-5.04 -0.809)"
                    strokeWidth="0.3"
                  />
                  <path
                    id="Path_57108"
                    data-name="Path 57108"
                    d="M15.079,20.192a.312.312,0,0,0-.076.619c2.685.329,3.553.951,3.553,1.2,0,.468-2.35,1.379-6.657,1.379s-6.657-.912-6.657-1.379c0-.267.852-.814,3.138-1.145a.312.312,0,0,0-.089-.617c-1.675.243-3.673.745-3.673,1.762,0,1.469,4.354,2,7.281,2s7.281-.533,7.281-2C19.18,20.866,16.61,20.38,15.079,20.192Z"
                    transform="translate(-4.619 -2.588)"
                    strokeWidth="0.3"
                  />
                </g>
              </g>
            </g>
          </svg>
          My Address
        </NavLink>
        <br />
        <NavLink to={"my-orders"}>
          <svg
            id="svgexport-10_30_"
            data-name="svgexport-10 (30)"
            xmlns="http://www.w3.org/2000/svg"
            width="20.569"
            height="20.582"
            viewBox="0 0 20.569 20.582"
          >
            <g id="Layer_32" data-name="Layer 32" transform="translate(0)">
              <path
                id="Path_57104"
                data-name="Path 57104"
                d="M18.953,12.974V6.357a.355.355,0,0,0-.177-.309l-8.6-4.967a.355.355,0,0,0-.355,0l-8.6,4.967a.355.355,0,0,0-.177.309v9.935a.355.355,0,0,0,.177.309l8.6,4.967a.355.355,0,0,0,.355,0L13.4,19.7a4.52,4.52,0,1,0,5.549-6.742ZM9.994,1.8l7.895,4.559-3.96,2.285L6.6,3.759Zm0,9.119L2.1,6.357l3.814-2.2L13.24,9.039ZM1.744,6.971,9.639,11.53v9.115L1.744,16.086Zm8.6,13.675V11.53l3.193-1.856v2.764a.355.355,0,1,0,.71,0V9.266l3.974-2.3v5.752a4.414,4.414,0,0,0-1.153-.156,4.517,4.517,0,0,0-4.034,6.525Zm6.742.259A3.814,3.814,0,1,1,20.9,17.09,3.814,3.814,0,0,1,17.09,20.9Z"
                transform="translate(-1.034 -1.033)"
              />
              <path
                id="Path_57105"
                data-name="Path 57105"
                d="M18.386,14.852l-2.2,2.9L15.033,16.4a.358.358,0,0,0-.55.458l1.419,1.7a.344.344,0,0,0,.543-.018l2.466-3.254a.355.355,0,1,0-.525-.429Z"
                transform="translate(-0.645 -0.637)"
              />
            </g>
          </svg>
          My Orders
        </NavLink>
        <br />
        <NavLink to={"about"}>
          <svg
            id="about"
            xmlns="http://www.w3.org/2000/svg"
            width="21.023"
            height="21.023"
            viewBox="0 0 21.023 21.023"
          >
            <g id="about-arrow-direction-button-pointer">
              <path
                id="Path_56216"
                data-name="Path 56216"
                d="M11.312,21.823A10.512,10.512,0,1,1,21.823,11.312,10.512,10.512,0,0,1,11.312,21.823Zm0-20.323a9.811,9.811,0,1,0,9.811,9.811A9.811,9.811,0,0,0,11.312,1.5Z"
                transform="translate(-0.8 -0.8)"
              />
              <path
                id="Path_56219"
                data-name="Path 56219"
                d="M12.552,9.1A1.752,1.752,0,1,1,14.3,7.352,1.752,1.752,0,0,1,12.552,9.1Zm0-2.8A1.051,1.051,0,1,0,13.6,7.352,1.051,1.051,0,0,0,12.552,6.3Z"
                transform="translate(-2.04 -1.395)"
              />
              <path
                id="Path_56220"
                data-name="Path 56220"
                d="M12.349,19.51c-.027,0-.053,0-.08,0a1.752,1.752,0,0,1-1.668-1.75V13.2a1.4,1.4,0,1,1,0-2.8h1.752a1.754,1.754,0,0,1,1.752,1.752v5.527a1.876,1.876,0,0,1-.58,1.35A1.7,1.7,0,0,1,12.349,19.51ZM10.6,11.1a.7.7,0,0,0,0,1.4h.35a.35.35,0,0,1,.35.35v4.905a1.049,1.049,0,0,0,1,1.051.991.991,0,0,0,.739-.287,1.171,1.171,0,0,0,.363-.844V12.152A1.051,1.051,0,0,0,12.354,11.1Z"
                transform="translate(-1.842 -1.991)"
              />
            </g>
          </svg>
          About
        </NavLink>
      </div>
      <div className="bottom">
        <a
          onClick={handleLogOut}
          className="logout"
          style={{ cursor: "pointer" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.932"
            height="21.869"
            viewBox="0 0 20.932 21.869"
          >
            <g
              id="svgexport-10_28_"
              data-name="svgexport-10 (28)"
              transform="translate(-2.034 -1.065)"
            >
              <g id="Group_8884" data-name="Group 8884">
                <path
                  id="Path_57113"
                  data-name="Path 57113"
                  d="M11.612,2.065a4,4,0,1,0,4,4,4,4,0,0,0-4-4Zm-5,4a5,5,0,1,1,5,5,5,5,0,0,1-5-5Z"
                  fill="red"
                  fillRule="evenodd"
                />
                <path
                  id="Path_57114"
                  data-name="Path 57114"
                  d="M11.077,14.218a.5.5,0,0,1-.473.526,17.641,17.641,0,0,0-7.354,2.04.408.408,0,0,0-.216.36v3.672a.5.5,0,0,0,.5.5h7.044a.5.5,0,0,1,0,1H3.534a1.5,1.5,0,0,1-1.5-1.5V17.143A1.408,1.408,0,0,1,2.781,15.9a18.642,18.642,0,0,1,7.771-2.155.5.5,0,0,1,.526.473Z"
                  fill="red"
                  fillRule="evenodd"
                />
                <path
                  id="Path_57115"
                  data-name="Path 57115"
                  d="M19.965,12.255a2.4,2.4,0,0,0-1.806-.815H16.251a2.408,2.408,0,0,0-2.408,2.408v6.678a2.408,2.408,0,0,0,2.408,2.408h1.908a2.4,2.4,0,0,0,1.806-.815.5.5,0,1,0-.75-.662,1.4,1.4,0,0,1-1.056.477H16.251a1.408,1.408,0,0,1-1.408-1.408V13.849a1.408,1.408,0,0,1,1.408-1.408h1.908a1.4,1.4,0,0,1,1.056.477.5.5,0,1,0,.75-.662Z"
                  fill="red"
                  fillRule="evenodd"
                />
                <path
                  id="Path_57116"
                  data-name="Path 57116"
                  d="M22.82,16.834a.5.5,0,0,1,0,.707l-2,2a.5.5,0,0,1-.707-.707l2-2A.5.5,0,0,1,22.82,16.834Z"
                  fill="red"
                  fillRule="evenodd"
                />
                <path
                  id="Path_57117"
                  data-name="Path 57117"
                  d="M22.82,17.541a.5.5,0,0,0,0-.707l-2-2a.5.5,0,0,0-.707.707l2,2A.5.5,0,0,0,22.82,17.541Z"
                  fill="red"
                  fillRule="evenodd"
                />
                <path
                  id="Path_57118"
                  data-name="Path 57118"
                  d="M16.466,17.188a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,0,1h-5A.5.5,0,0,1,16.466,17.188Z"
                  fill="red"
                  fillRule="evenodd"
                />
              </g>
            </g>
          </svg>
          Logout
        </a>
      </div>
    </section>
  );
};

export default Menu;
