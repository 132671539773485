import "./index.scss";
import filter from "../../assets/filter.png";
import Item from "../../components/item";
import times from "../../assets/times.png";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CategoryType from "./../../components/Category";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { apiLink } from "../../api";
import ReactLoading from 'react-loading';

import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import benshtyLogo from '../../assets/benehsty.jfif'
const Category = () => {
  const [typeId, setTypeId] = useState(null);
  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [products, setProducts] = useState([]);
  const [valueRange, setValue] = useState([199, 400]);
  const [activePopup, setPopup] = useState(false);
  const [filterdData, setfilteredData] = useState();
  const languageState = useSelector((state) => state.language.english);
  // selected items
  const [selectedSize, setSelectedSize] = useState();
  const [selectedColor, setSelectedColor] = useState();
  // active state
  const [activeTypeIndex, setActiveIndex] = useState(null);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(null);
  const [activeColorIndex, setActiveColorIndex] = useState(null);
  const [activesizeIndex, setActiveSizeIndex] = useState(null);

  //
  const [pathName, setPathName] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setTypeId(localStorage.getItem("typeId"));
  }, [typeId]);

  useEffect(() => {
    if (typeId !== null) {
      axios({
        method: "GET",
        url: `${apiLink}/api/category/view/${typeId}`,
      }).then((response) => {
        setCategories(response.data);
      });
    }
  }, [typeId]);
  useEffect(() => {
    axios.get(`${apiLink}/api/type/view`).then((response) => {
      setTypes(response.data);
    });
  }, []);
  useEffect(() => {
    if (typeId !== null) {
      axios.get(`${apiLink}/api/productByType/${typeId}`).then((response) => {
        setProducts(response.data.data);
      });
    }
  }, [typeId]);
  useEffect(() => {
    axios
      .get(`${apiLink}/api/productByCategory/${categoryId}`)
      .then((response) => {
        console.log(response.data.data);
        setProducts(response.data.data);
      });
  }, [categoryId]);
  const handleType = (id, index) => {
    setTypeId(id);
    setActiveIndex(index);
    setActiveCategoryIndex(null);
  };
  const handleCategory = (id, index) => {
    // setProducts(null)
    setCategoryId(id);
    setActiveCategoryIndex(index);
    setSelectedColor(null);
    setSelectedSize(null);
    setActiveColorIndex(null);
    setActiveSizeIndex(null);
  };
  const choseColor = (item, index) => {
    console.log("chose color", item, index);
    setSelectedColor(item);
    setActiveColorIndex(index);
  };
  const choseSize = (item, index) => {
    console.log("chose size", item, index);
    setSelectedSize(item);
    setActiveSizeIndex(index);
  };

  const handleFilter = () => {
    axios
      .post(
        `${apiLink}/api/product/filter/${categoryId}?start=${valueRange[0]}&end=${valueRange[1]}&size=${selectedSize}&color_code=${selectedColor}`,
        {
          xsrfHeaderName: " X-CSRF-TOKEN",
        }
      )
      .then((response) => {
        console.log(response);
        setProducts(response.data.data);
      });

    setPopup(!activePopup);
  };
  useEffect(() => {
    axios.get(`${apiLink}/api/filter/${categoryId}`).then((response) => {
      setfilteredData(response.data);
      !response.data.start
        ? setValue([0, 0])
        : setValue([response.data.start, response.data.end]);
    });
  }, [categoryId]);
  const handleFilterPopup = () => {
    setPopup(!activePopup);
  };
  const valuetext = (value) => {
    return `${value}°C`;
  };
  return (
    <>
      <section className="categories">
        <div className="category-top">
          <div className="container">
            <div className="category-type">
              <ul>
                {types.map((item, index) => {
                  const activate = activeTypeIndex === index ? "active" : "";
                  return (
                    <NavLink
                      to={item.en_name}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleType(item.id, index);
                      }}
                      className={"type"}
                      key={item.id}
                    >
                      {languageState ? item.en_name : item.ar_name}
                      {/* {item.en_name} */}
                    </NavLink>
                  );
                })}
                {/* <NavLink to={"men"}>Men</NavLink>
                <NavLink to={"women"}>Women</NavLink>
                <NavLink to={"kids"}>Kids</NavLink>
                <NavLink to={"babies"}>Babies</NavLink>
                <NavLink to={"accessories"}>accessories</NavLink> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="category-banner">
          <div className="container flex-base">
            <h1> {languageState ? "Categories" : "الأقسام"} </h1>
            {/* <div className="filtration">
              <span> {languageState ? "filter By" : "فلتر"} </span>
              <img src={filter} alt="" onClick={handleFilterPopup} />
            </div> */}
          </div>
        </div>
        <div className="category-items">
          <div className="container flex-items">
            {categories.map((item, index) => {
              const activate =
                activeCategoryIndex === index ? "active-category" : "";
              return (
                <CategoryType
                  handleCategory={handleCategory}
                  style={{ cursor: "pointer" }}
                  key={item.id}
                  image={item.icon}
                  titleEn={item.en_name}
                  titleAr={item.ar_name}
                  language={languageState}
                  id={item.id}
                  activate={activate}
                  index={index}
                />
              );
            })}
          </div>
        </div>
        <div className="category-content">
          <div className="container">
            <div className="row text-center">
              {products.length > 0
                ? products.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="col-lg-3 col-md-6"
                        style={{ marginBottom: "2rem" }}
                      >
                        <Item
                          image={ item.color[0].image.length > 0 ? item.color[0].image[0].image : benshtyLogo}
                          price={item.price}
                          discount={item.discount}
                          id={item.id}
                          rete={item.rete}
                          title={item.en_name}
                        />
                      </div>
                    );
                  })
                : <ReactLoading type={'bubbles'} color={'#601c7e'} height={'100vh'} width={200} className={'loading'} />}
            </div>
          </div>
        </div>
        <div className={`filertPopup ${activePopup ? "" : "hiidenPopup "}`}>
          <div className="box">
            <div className="top">
              <h2 style={{ textTransform: "uppercase" }}>Filter By</h2>
              <img
                src={times}
                style={{ height: "25px" }}
                alt=""
                onClick={handleFilterPopup}
              />
            </div>
            <div className="colors">
              <h4>Color</h4>
              {filterdData
                ? filterdData.color.map((item, index) => {
                    const activate =
                      activeColorIndex === index ? "active-size" : "";
                    return (
                      <span
                        key={item}
                        style={{
                          backgroundColor: item,
                          border: "1.5px solid rgb(190 190 190)",
                        }}
                        onClick={() => {
                          choseColor(item, index);
                        }}
                        className={activate}
                      ></span>
                    );
                  })
                : ""}
            </div>
            <div className="sizes">
              <h4>Size</h4>
              {filterdData
                ? filterdData.size.map((item, index) => {
                    const activate =
                      activesizeIndex === index ? "active-size" : "";
                    return (
                      <span
                        key={item}
                        onClick={() => {
                          choseSize(item, index);
                        }}
                        style={{ cursor: "pointer" }}
                        className={activate}
                      >
                        {item}
                      </span>
                    );
                  })
                : ""}
            </div>
            <Box sx={{ width: 300 }}>
              <Slider
                getAriaLabel={() => "Temperature range"}
                value={valueRange}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={filterdData ? filterdData.start : 0}
                max={filterdData ? filterdData.end : 0}
              />
            </Box>
            <div className="price-range">
              <h5>{valueRange[0]} EGP</h5>
              <h5>{valueRange[1]} EGP</h5>
            </div>
            <div className="options">
              <button className="apply" onClick={handleFilter}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Category;
