import address from "../../assets/address.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiLink } from "../../api";
const MyAddress = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    axios
      .get(`${apiLink}/api/address/user/view`, { headers })
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      });
  }, []);
  return (
    <div className="address">
      <div className="default-address">
        <h4>Default Address</h4>
        <div className="main-address">
          <img src={address} alt="" />
          <p>El Shrabassy St , Damietta , Egypt Building No 12 - Flat 2</p>
        </div>
      </div>
      <div className="all-addresses">
        <h4>All addresses</h4>
        {data
          ? data.map((item) => {
              return (
                <div className="other" key={item.id}>
                  <p>
                    {item.address}
                    {item.city}
                    {item.street_name}
                    {item.building_number}
                  </p>
                </div>
              );
            })
          : ""}
        {/* <div className="other">
          <p>
            El Shrabassy St , Damietta , Egypt El Shrabassy St , Damietta ,
            Egypt Building No 12 - Flat 2
          </p>
        </div>
        <div className="other">
          <p>
            El Shrabassy St , Damietta , Egypt El Shrabassy St , Damietta ,
            Egypt Building No 12 - Flat 2
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default MyAddress;
