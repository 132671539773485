import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import { Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";
import Banner from "./components/Banner";
import LandingPage from "./pages/landing-page/index";
import SignUp from "./pages/user/SignUp";
import Footer from "./components/Footer";
import SignIn from "./pages/user/SignIn";
import ForgetPassword from "./pages/user/forgetPassword";
import Category from "./pages/category";
import Branches from "./pages/branches";
import WishList from "./pages/wishlist";
import NewArrivals from "./pages/new-arriavls";
import BestSeller from "./pages/bestseller";
import Cart from "./pages/cart";
import DeleviryAddress from "./pages/cart/deliveryAddress";
import Profile from "./pages/profile";
import ContactUs from "./pages/contact-us";
import Notification from "./components/notification";
import ProductDetails from "./pages/product";
import PaymentType from "./pages/cart/cash-visa";
import Congratulation from "./pages/cart/after-order-submit";
import Orders from "./pages/profile/myOrders";
import OrderItem from "./components/orderItem";
import OrderDetails from "./pages/profile/order-details";
import Varfication from "./pages/user/verification";
function App() {
  return (
    <React.Fragment>
      <Banner />
      <Nav />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/category/*" element={<Category />} />
        <Route path="/branches" element={<Branches />} />
        <Route path="/wishlist" element={<WishList />} />
        <Route path="/new-arrivals" element={<NewArrivals />} />
        <Route path="/bestseller" element={<BestSeller />} />
        <Route path="/shipping-cart" element={<Cart />} />
        <Route path="/address" element={<DeleviryAddress />} />
        <Route path="/checkout" element={<PaymentType />} />
        <Route path="/profile/*" element={<Profile />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/product-details/:id" element={<ProductDetails />} />
        <Route path="/congratulation" element={<Congratulation />} />
          <Route path="/verification" element={<Varfication />} />

      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
