import "./forms.scss";
import React from "react";
import { Formik } from "formik";
import gmail from "../../assets/gmail.png";
import facebook from "../../assets/facebook.png";
import apple from "../../assets/apple.png";
import * as Yup from "yup";
import axios from "axios";
import { apiLink } from "../../api";
const SignupSchemaEn = Yup.object().shape({
  user_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(8, "minimum 8 digts").required("Required"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "passwords doesn't match")
    .required("Required"),
});

const SignupSchemaAr = Yup.object().shape({
  user_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("مطلوب"),
  phone: Yup.number().required("مطلوب"),
  email: Yup.string().email("بريد إلكتروني خاطئ").required("مطلوب"),
  password: Yup.string().min(8, "٨ أرقام كحد أدنى").required("مطلوب"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "كلمات السر لا تتطابق")
    .required("مطلوب"),
});

const Register = ({ navigate, language }) => (
  <div>
    <Formik
      validationSchema={language ? SignupSchemaEn : SignupSchemaAr}
      initialValues={{
        user_name: "",
        phone: "",
        email: "",
        password: "",
        password_confirmation: "",
      }}
      onSubmit={ (values) => {
        console.log(values);
        localStorage.setItem("phone-number", values.phone);
        // values.phone = values.phone.toString()
        axios
          .post(`${apiLink}/api/register`, values, {
            xsrfHeaderName: " X-CSRF-TOKEN",
          })
          .then((response) => {
            console.log(response);
            // localStorage.setItem("token", response.data.data.access_token);
            navigate("/verification");
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className="main-form">
          <input
            className={errors.user_name ? "error" : ""}
            type="text"
            name="user_name"
            placeholder={language ? "username" : "اسم المستخدم"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.user_name}
          />

          <span className="span-error">
            {errors.user_name && touched.user_name && errors.user_name}
          </span>
          <input
            className={errors.phone ? "error" : ""}
            type="text"
            name="phone"
            placeholder={language ? "Phone number" : "رقم الهاتف"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            // pattern="/^01[0125][0-9]{8}$/"
          />
          <span className="span-error">
            {errors.phone && touched.phone && errors.phone}
          </span>

          <input
            className={errors.email ? "error" : ""}
            type="email"
            name="email"
            placeholder={language ? "Email" : "الايميل"}
            onChange={handleChange}
            value={values.email}
          />
          <span className="span-error">
            {errors.email && touched.email && errors.email}
          </span>

          <input
            className={errors.password ? "error" : ""}
            type="password"
            name="password"
            placeholder={language ? "password" : "كلمة السر"}
            onChange={handleChange}
            value={values.password}
          />
          <span className="span-error">
            {errors.password && touched.password && errors.password}
          </span>

          <input
            className={
              errors.dontmatch || errors.password_confirmation ? "error" : ""
            }
            type="password"
            name="password_confirmation"
            placeholder={language ? "confirm password" : "تأكيد كلمة السر"}
            onChange={handleChange}
            value={values.password_confirmation}
            min="8"
          />

          <span className="span-error">
            {errors.password_confirmation &&
              touched.password_confirmation &&
              errors.password_confirmation}
          </span>

          <button type="submit" disabled={isSubmitting}>
            {language ? "create account" : "إنشاء حساب"}
          </button>

          <span>or sign up with</span>
          <div className="social">
            <img src={gmail} alt="" />
            <img src={facebook} alt="" />
            <img src={apple} alt="" />
          </div>
        </form>
      )}
    </Formik>
  </div>
);

export default Register;
