import higher from "../../assets/higher.png";
import Checkout from "./checkoutItem";
import { Formik, Field, Form } from "formik";
import "./index.scss";
import { useState } from "react";
import border from "../../assets/border.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { apiLink } from "../../api";
import { useNavigate } from "react-router-dom";
import {resetProducts} from "../../redux-state/reducers";
import {useDispatch} from "react-redux";
import credit from '../../assets/credit.png';
import cash from '../../assets/cash.png';
const paymentMethod = [{ text:"cash" , image:cash}, {text:"online",image:credit}];

const PaymentType = () => {
  const [mobileState, setMobileState] = useState();
  const globalTotal = useSelector((state) => state.cart.totalPrice);
  let navigate = useNavigate();
  let dispatch = useDispatch()
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };

  const handleRadioButton = (cashType) => {
    localStorage.setItem("paymentType", cashType);
  };
  const handleChangeMobile = (event) => {
    localStorage.setItem("phone", `${event.target.value}`);
  };
  const handleSubmitOrder = () => {
    const productsFromLocal = JSON.parse(localStorage.getItem("cart"));
    productsFromLocal.forEach((item) => {
      // delete item["productImage"];
      delete item["product_ar_name"];
      delete item["product_en_name"];
      delete item["discount"];
      delete item["price"];
    });
    console.log(productsFromLocal);
    const products = productsFromLocal.map((item) => {
      return {
        product_id: item.productId,
        size: item.size,
        color: item.color,
        image: item.productImage,
        quantity: item.quantity,
        size_id : item.productTitle.color[0].size[0].id,
        name: item.productTitle.en_name
      };
    });
    console.log(products);
    let addressId = localStorage.getItem("addressId");
    const order = {
      // city: localStorage.getItem('city'),
      city: localStorage.getItem("city"),
      another_phone: localStorage.getItem("phone"),
      // address_id: localStorage.getItem("addressId"),
      address_id: addressId,
      promocode: "15%",
      shipping: 20,
      payment_type: localStorage.getItem("paymentType"),
      data: products,
    };

    console.log(order);
    axios.post(`${apiLink}/api/order`, order, { headers }).then((response) => {
      console.log(response);
      navigate("/congratulation");
     localStorage.setItem("cart","[]")
      dispatch(resetProducts())
    });
  };

  return (
    <section className="delivery-address">
      <div className="container">
        <p className="head-line">Shipping cart</p>
        <img className="higher" src={higher} alt="" />
        <p className="head-line">Delivery Address</p>
        <img className="higher" src={higher} alt="" />
        <p className="second-title">CHECKOUT</p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <h5
              style={{
                borderLeft: "5px solid #FFB31C",
                paddingLeft: "1rem",
              }}
            >
              Another Mobile
            </h5>
            <form>
              <div className="row">
                <div className="col-lg-7">
                  <input
                    type="number"
                    className="input-address inputs"
                    placeholder="Another Mobile Number"
                    value={mobileState}
                    onChange={handleChangeMobile}
                  />
                </div>
              </div>
            </form>
            {/*<Formik*/}
            {/*    initialValues={{*/}
            {/*        address: "",*/}
            {/*    }}*/}
            {/*    onSubmit={(values) => {*/}
            {/*        console.log(values);*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {({values}) => (<Form>*/}
            {/*        <div role="group" aria-labelledby="my-radio-group"></div>*/}
            {/*    </Form>)}*/}
            {/*</Formik>*/}

            {/*<Formik*/}
            {/*    initialValues={{*/}
            {/*        address: "", city: "", street_name: "", building_number: "", flat_number: "",*/}
            {/*    }}*/}
            {/*    onSubmit={(values) => {*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {({values}) => (<Form>*/}
            {/*        <div className="form-group">*/}
            {/*            <div className="row">*/}
            {/*                <div className="col-lg-7">*/}
            {/*                    <Field*/}
            {/*                        type="number"*/}
            {/*                        name="address"*/}
            {/*                        className="input-address inputs"*/}
            {/*                        placeholder="Another Mobile Number"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Form>)}*/}
            {/*</Formik>*/}
            <h5
              style={{
                borderLeft: "5px solid #FFB31C",
                paddingLeft: "1rem",
              }}
            >
              Payment Method
            </h5>
            {/*<Formik*/}
            {/*  initialValues={{*/}
            {/*    address: "",*/}
            {/*    city: "",*/}
            {/*    street_name: "",*/}
            {/*    building_number: "",*/}
            {/*    flat_number: "",*/}
            {/*  }}*/}
            {/*  onSubmit={(values) => {}}*/}
            {/*>*/}
            {/*  {({ values }) => (*/}
            {/*    <Form>*/}
            {/*      <div className="form-group">*/}
            {/*        <div className="row">*/}
            {/*          <div className="box">*/}
            {/*            <label className="address-title">*/}
            {/*              <span>Cash With Delievary</span>*/}
            {/*            </label>*/}

            {/*            <Field type="radio" name="address" value={"gameed"} />*/}
            {/*          </div>*/}
            {/*          <div className="box">*/}
            {/*            <label className="address-title">*/}
            {/*              <span>Online Payment</span>*/}
            {/*            </label>*/}

            {/*            <Field type="radio" name="address" value={"gameed"} />*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </Form>*/}
            {/*  )}*/}
            {/*</Formik>*/}
            <form>
              <div role="group" aria-labelledby="my-radio-group">
                {paymentMethod.map((item) => {
                  return (
                    <div key={item.text} className="box">
                      <label className="address-title"> <img src={item.image}/> {item.text} </label>
                      <input
                        type="radio"
                        name="cash-method"
                        value={item.text}
                        onClick={() => {
                          handleRadioButton(item.text);
                        }}
                      />
                    </div>
                  );
                })}
                {/*<div className="box">*/}
                {/*    <label className="address-title">Cash with Delievary</label>*/}
                {/*    <input type='radio' value='cash'/>*/}
                {/*</div>*/}
                {/*<div className="box">*/}
                {/*    <label className="address-title">Online Payment</label>*/}
                {/*    <input type='radio' value='online-payment'/>*/}
                {/*</div>*/}
                {/*</div>*/}
              </div>
            </form>
          </div>

          {/*<Checkout buttonTitle={"DONE"}/>*/}
          <div className="col-md-3 cart-checkout">
            <h4 className="text-center">order summary</h4>
            <hr className="hr-title" />
            <div className="subtotal set-flex">
              <h5>subtotal</h5>
              <span>{globalTotal} EGP</span>
            </div>
            <div className="delivery  set-flex">
              <h5>delivery fee</h5>
              <span>22 EGP</span>
            </div>
            <div className="promocode  set-flex">
              <input placeholder="promocode" type="text" />
              <button>Apply</button>
            </div>
            <img src={border} alt="" />
            <div className="total set-flex">
              <h5>Total</h5>
              <span>{globalTotal} EGP</span>
            </div>
            {/*<Link to={`/${url}`} className="checkout">*/}
            {/*    NEXT*/}
            {/*</Link>*/}
            <button className="checkout" onClick={handleSubmitOrder}>
              Checkout
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentType;
