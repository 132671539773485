import location from "../assets/location.png";
import arrow from "../assets/arrow.png";
import { Link } from "react-router-dom";
const BranchItem = ({
  titleEn,
  locationMap,
  titleAr,
  addressEn,
  addressAr,
  language,
}) => {
  return (
    <div className="branch-item">
      <div className="branch-heading">
        <img src={location} alt="" />
        <h3>{language ? titleEn : titleAr}</h3>
      </div>
      <div className="bransh-address">
        <p>{language ? addressEn : addressAr}</p>

        <a className="location" href={locationMap}>
          {language ? "Location" : "الموقع"}
          <img src={arrow} alt="arrow" />
        </a>
      </div>
    </div>
  );
};

export default BranchItem;
