import img1 from "../../assets/onboarding-1.png";
import img2 from "../../assets/mobile.png";
import applestore from "../../assets/applestore.png";
import andriod from "../../assets/andriod.png";
const MobileApp = () => {
  return (
    <section className="mob-sec">
      <div className="container">
        <div className="mobile-app">
          <div className="right">
            <div className="content">
              <h3>Beneshty Mobile Application</h3>
              <p>
                Now you can get the mobile application from <br /> Beneshty and
                order the suitable clothes for you <br /> with easy way. What
                are you waiting for <br /> download it now
              </p>
              <div className="buttons">
                <img style={{ marginRight: "10px" }} src={applestore} alt="" />
                <img src={andriod} alt="" />
              </div>
            </div>
          </div>
          <div className="images">
            <img src={img1} alt="" />
            <img className="mobile-2" src={img2} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileApp;
