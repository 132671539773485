import Logo from "../../assets/logo-2.png";
import contact from "../../assets/contactus.png";
import call from "../../assets/call.png";
import send from "../../assets/send.png";
import instagram from "../../assets/instagram.png";
import facebook2 from "../../assets/facebook2.png";
import whatsapp from "../../assets/whatsapp.png";
import telegram from "../../assets/telegram.png";
import "./index.scss";
import { useSelector } from "react-redux";
const ContactUs = () => {
  const languageState = useSelector((state) => state.language.english);
  return (
    <section className="content-us">
      <div className="container">
        <div className="row">
          <div className="col-md-6 left">
            <img src={Logo} alt="" />
            <h3> {languageState ? "Contact Us" : "تواصل معنا"} </h3>

            {languageState ? (
              <p>
                Feel free to contact-us and we will <br /> get back to you as
                soon as it possible
              </p>
            ) : (
              <p>لا تتردد في الاتصال بنا وسنعاود الاتصال بك في أقرب وقت ممكن</p>
            )}
          </div>
          <div className="col-md-6 right">
            <div className="call">
              <img src={contact} alt="" />
              <div className="number">
                <img src={call} alt="" />
                <span>
                  <p className="call-title">Call Us On</p>
                  <p className="call-number">012109111119</p>
                </span>
              </div>
              <div className="email">
                <img src={send} alt="" />
                <div className="email-content">
                  <p
                    className="email-title"
                    style={{ marginBottom: "0px", textAlign: "start" }}
                  >
                    Send Email
                  </p>
                  <p className="email-content">Support@Beneshty.Com</p>
                </div>
              </div>
              <div className="social">
                <img src={instagram} alt="" />
                <img src={facebook2} alt="" />
                <img src={whatsapp} alt="" />
                <img src={telegram} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
