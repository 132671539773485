import stars from "../assets/stars.png";
import heart from "../assets/heart.png";
import cart from "../assets/cart2.png";

import { useNavigate } from "react-router-dom";
import axios from "axios";
const token = localStorage.getItem("token");
const headers = { Authorization: `Bearer ${token}` };
const priceCheck = (discount, price) => {
  if (discount === null || discount == 0) {
    return <span className="item-price">{price} EGP</span>;
  } else {
    const myDiscount = discount / 100;
    const calcuteDiscount = price * myDiscount;
    const totalPrice = price - calcuteDiscount;
    return (
      <>
        <span className="item-price">({price}EGP)</span>
        <span className="item-discount">{totalPrice}EGP</span>
      </>
    );
  }
};
const rateCheck = (rate) => {
  if (rate === null) {
    return null;
  } else {
    return (
      <>
        <img src={stars} alt="" />
        <span>{rate}</span>
      </>
    );
  }
};

const deleteFromWishList = (id) => {
  console.log(id);
  axios.post(
    `https://beneshty.oncreation.net/api/wishlist/delete/${id}`,
    {},
    { headers }
  );
};

const WishListItem = ({ image, main, price, title, discount, rate, id }) => {
  let navigate = useNavigate();
  const handleClick = (e) => {
    if (e.target.className === "main") {
      navigate(`/product-details/${id}`);
    }
  };
  return (
    <div onClick={handleClick} className={`best-item ${main}`}>
      <img className="main" src={image} alt="" />
      <div className="options">
        <img
          src={heart}
          alt="heart"
          onClick={() => {
            deleteFromWishList(id);
          }}
        />
        <img src={cart} alt="cart" />
      </div>
      <div className="bottom">
        <div className="left">
          <h5 className="title">{title}</h5>
          <img src={stars} alt="" />
          {/* <span>{rate}</span> */}
          <span className="rate">4.2</span>
        </div>
        <div className="right">{priceCheck(discount, price)}</div>
      </div>
    </div>
  );
};

export default WishListItem;
