import "./Banner.scss";
const Banner = () => {
  return (
    <div className="banner text-center">
      <h4 style={{ color: "white" }}>Free shipping on orders over 300 EGP</h4>
    </div>
  );
};

export default Banner;
