import React from "react";
import "./index.scss";
import branchlogo from "../../assets/branches-logo.png";
import location from "../../assets/location.png";
import arrow from "../../assets/arrow.png";
import BranchItem from "../../components/BranchItem";
import axios from "axios";
import { useState, useEffect } from "react";
import { apiLink } from "../../api";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
const Branches = () => {
  const [branches, setBranches] = useState();
  useEffect(() => {
    axios.get(`${apiLink}/api/branches/view`).then((response) => {
      setBranches(response.data);
      console.log(response.data);
    });
  }, []);
  const globalLanguage = useSelector((state) => state.language.english);
  return (
    <section className="branches">
      <div className="container">
        <div className="branches-top">
          <h1> {globalLanguage ? "our branches" : "فروعنا"} </h1>
          <img src={branchlogo} alt="" />
        </div>
        <div className="branches-content">
          {branches ? branches.map((item)=>{
            return <React.Fragment key={item.id}>
                    <BranchItem
                      titleEn={item.en_name}
                      addressEn={item.en_address}
                      locationMap={item.location}
                      addressAr={item.ar_address}
                      titleAr={item.ar_name}
                      language={globalLanguage}
                    />
                  </React.Fragment>
          }) :  <ReactLoading type={'bubbles'} color={'#601c7e'} height={'100vh'} width={200} className={'loading'} />}
          {/*{branches.map((item) => {*/}
          {/*  return (*/}
          {/*    <React.Fragment key={item.id}>*/}
          {/*      <BranchItem*/}
          {/*        titleEn={item.en_name}*/}
          {/*        addressEn={item.en_address}*/}
          {/*        locationMap={item.location}*/}
          {/*        addressAr={item.ar_address}*/}
          {/*        titleAr={item.ar_name}*/}
          {/*        language={globalLanguage}*/}
          {/*      />*/}
          {/*    </React.Fragment>*/}
          {/*  );*/}
          {/*})}*/}

        </div>
      </div>
    </section>
  );
};

export default Branches;
