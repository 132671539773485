import "./forms.scss";
import React, { useEffect } from "react";
import { Formik } from "formik";
import gmail from "../../assets/gmail.png";
import facebook from "../../assets/facebook.png";
import apple from "../../assets/apple.png";
import * as Yup from "yup";
import axios from "axios";
import { apiLink } from "../../api";
const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(8, "minimum 8 digts").required("Required"),
});
const Login = ({ navigate }) => {
  return (
    <div>
      <Formik
        validationSchema={SignupSchema}
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={async (values) => {
          const data = {
            email: values.email,
            password: values.password,
          };
          console.log(data);
          await axios
            .post(`${apiLink}/api/login`, data, {
              xsrfHeaderName: " X-CSRF-TOKEN",
            })
            .then((response) => {
              console.log(response);
              localStorage.setItem("token", response.data.data.access_token);
              navigate("/");
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className="main-form">
            <input
              className={errors.email ? "error" : ""}
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={values.email}
            />
            <span className="span-error">
              {errors.email && touched.email && errors.email}
            </span>

            <input
              className={errors.password ? "error" : ""}
              type="password"
              name="password"
              placeholder="password"
              onChange={handleChange}
              value={values.password}
            />
            <span className="span-error">
              {errors.password && touched.password && errors.password}
            </span>

            <button type="submit" disabled={isSubmitting}>
              create account
            </button>

            <span>or sign up with</span>
            <div className="social">
              <img src={gmail} alt="" />
              <img src={facebook} alt="" />
              <img src={apple} alt="" />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
