import "./index.scss";
import CartItem from "./cartItem";
import Checkout from "./checkoutItem";
import { useSelector } from "react-redux";
import emptyCart from "../../assets/empty-cart.png";
import Item from "./../../components/item";

const Cart = () => {
  const globalState = useSelector((state) => state);
  const languageState = useSelector((state) => state.language.english);
  const products = JSON.parse(localStorage.getItem("cart"));
  console.log(products);
  console.log(globalState);
  return (
    <section className="shipping-cart">
      {console.log(globalState)}
      <div className="container">
        <h2 className="head-line">
          {languageState ? "Shipping cart" : "عربة الشحن"}
        </h2>
        <div className="row">
          {products.length > 0 ? (
            <>
              <div className="col-md-8 cart-items">
                <div className="titles">
                  <h4 className="title-pro">Product</h4>
                  <h4 className="title-qty">Quantity</h4>
                  <h4 style={{ marginRight: "3rem" }}>Totall</h4>
                  <h4></h4>
                </div>
                <div className="products">
                  {products.map((item, index) => {
                    return (
                      <CartItem
                        key={item.productId}
                        titleNameAr={item.ar_name}
                        titleNameEn={item.product_en_name}
                        image={item.productImage}
                        price={item.price}
                        discount={item.discount}
                        sizeId={item.sizeId}
                        colorId={item.colorId}
                        id={item.productId}
                        quantity={item.quantity}
                        index={index}
                      />
                    );
                  })}
                </div>
              </div>
              <Checkout url={"address"} buttonTitle={`CHECKOUT`} />
            </>
          ) : (
            <div className="text-center">
              <img src={emptyCart} alt="" />
              <h4 style={{ color: "#601C7E", textTransform: "uppercase" }}>
                {languageState
                  ? "Your Cart Is Empty Now"
                  : "عربة التسوق الخاصة بك فارغة الآن"}
              </h4>
              <span style={{ color: "#8F8F8F", textTransform: "uppercase" }}>
                {languageState ? " go back to shopping" : "ارجع إلى التسوق"}
              </span>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Cart;
