import "./SignUp.scss";
import Logo from "../../assets/logo-2.png";
import Register from "./register";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const SignUp = () => {
  const navigate = useNavigate();
  const languageState = useSelector((state) => state.language.english);
  return (
    <section className="signup">
      <div className="container">
        <div className="row ">
          <div className="col describe">
            <img src={Logo} alt="" />
            <h2> {languageState ? "Sign Up" : "تسجيل"} </h2>

            {languageState ? (
              <p>
                Please enter the following data to <br /> create new account &
                if you Already <br /> Have Account
              </p>
            ) : (
              <p>
                الرجاء إدخال البيانات التالية لإنشاء حساب جديد <br /> وإذا كان
                لديك حساب بالفعل
              </p>
            )}
            <Link to={"/login"} className="login-btn">
              {languageState ? "Login" : "تسجيل الدخول"}
            </Link>
          </div>
          <div className="col">
            <Register language={languageState} navigate={navigate} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
