import { Link } from "react-router-dom";
import border from "../../assets/border.png";
import { useSelector } from "react-redux";

const Checkout = ({ url, buttonTitle }) => {
  const globalTotal = useSelector((state) => state.cart.totalPrice);
  return (
    <div className="col-md-3 cart-checkout">
      <h4 className="text-center">order summary</h4>
      <hr className="hr-title" />
      <div className="subtotal set-flex">
        <h5>subtotal</h5>
        <span>{globalTotal} EGP</span>
      </div>
      <div className="delivery  set-flex">
        <h5>delivery fee</h5>
        <span>22 EGP</span>
      </div>
      <div className="promocode  set-flex">
        <input placeholder="promocode" type="text" />
        <button>Apply</button>
      </div>
      <img src={border} alt="" />
      <div className="total set-flex">
        <h5>Total</h5>
        <span>{globalTotal} EGP</span>
      </div>
      <Link to={`/${url}`} className="checkout">
        {buttonTitle}
      </Link>
    </div>
  );
};

export default Checkout;
