import "./Footer.scss";
import Logo from "../assets/logo-footer.png";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import instagramIcon from "../assets/instagram-icon.png";
import whatsappIcon from "../assets/whatsIcon.png";
import telegramIcon from "../assets/telegram-icon.png";
import facebookIcon from "../assets/facebook-icon.png";
import callIcon from "../assets/call-icon.png";
import messagesIcon from "../assets/messages-icon.png";
const Footer = () => {
  const languageState = useSelector((state) => state.language.english);
  const handleFooter = (id) => {
    document.documentElement.scrollTop = 0;
    localStorage.setItem("typeId", id);
    console.log(id);
  };
  return (
    <footer>
      <div className="container">
        <section className="subscribe">
          <div className="left">
            <div>
              <h1>
                {languageState
                  ? "Subscribe To Get Our News"
                  : "شترك للحصول على أخبارنا"}
              </h1>
              <form className="text-center">
                <input type="text" placeholder="Enter Your Email" />
                <button> {languageState ? "Subscribe" : "اشترك"} </button>
              </form>
            </div>
          </div>
        </section>
        <div className="footer-data">
          <div className="row">
            <div className="col footer-content">
              <img src={Logo} alt="" />
              <div className="right">
                <div className="quick-link">
                  <h5> {languageState ? "Quick links" : "روابط سريعة"}</h5>
                  <ul>
                    <NavLink to={"/"} onClick={handleFooter}>
                      {languageState ? "Home" : "الرئيسية"}
                    </NavLink>

                    <NavLink
                      to={"category/WOMAN"}
                      onClick={() => {
                        handleFooter(1);
                      }}
                    >
                      {languageState ? "Women" : "امرأة"}
                    </NavLink>

                    <NavLink
                      to={"category/Men"}
                      onClick={() => {
                        handleFooter(2);
                      }}
                    >
                      {languageState ? "Men" : "رجال"}
                    </NavLink>

                    <NavLink
                      to={"category/Kids"}
                      onClick={() => {
                        handleFooter(3);
                      }}
                    >
                      {languageState ? "KIDS" : "صغار السن"}
                    </NavLink>
                  </ul>
                </div>
                <div className="pages">
                  <br />
                  <ul>
                    <NavLink
                      to={"category/accessories"}
                      onClick={() => {
                        handleFooter(4);
                      }}
                    >
                      {languageState ? "accessories" : "مستلزمات"}
                    </NavLink>

                    <NavLink
                      to={"category/BABY"}
                      onClick={() => {
                        handleFooter(5);
                      }}
                    >
                      {languageState ? "babes" : "أطفال"}
                    </NavLink>

                    <NavLink to={"branches"} onClick={handleFooter}>
                      {languageState ? "branches" : "الفروع"}
                    </NavLink>

                    <NavLink to={"wishlist"} onClick={handleFooter}>
                      {languageState ? "wishlist" : "قائمة الرغبات"}
                    </NavLink>
                  </ul>
                </div>
                <div className="social">
                  <h5> {languageState ? "Be social" : " التواصل الاجتماعي"}</h5>
                  <ul>
                    <a>
                      {" "}
                      <img src={telegramIcon} alt="" />{" "}
                      {languageState ? "telegram" : "تيليجرام"}
                    </a>
                    <a>
                      {" "}
                      <img src={facebookIcon} alt="" />{" "}
                      {languageState ? "facebook" : "فيسبوك"}
                    </a>
                    <a>
                      {" "}
                      <img src={callIcon} alt="" /> 01554043243
                    </a>
                  </ul>
                </div>
                <div className="social">
                  <br />
                  <ul>
                    <a>
                      <img src={instagramIcon} alt="" />{" "}
                      {languageState ? "instagram" : "انستجرام"}
                    </a>

                    <a>
                      <img src={whatsappIcon} alt="" />
                      {languageState ? "Whatsapp" : "واتس اب"}
                    </a>
                    <a>
                      <img src={messagesIcon} alt="" /> abdd.yahia@gmail.com
                    </a>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-copy">
              <div className="footer-copy-details">
                <span>
                  {languageState
                    ? "© Copyright 2022by OnCreation. All right reserved."
                    : "© حقوق الطبع والنشر 2022 بواسطة OnCreation. جميع الحقوق محفوظة"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
