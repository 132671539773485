import Menu from "./menu";
import { Route, Routes } from "react-router-dom";
import PersonalInfo from "./personalInformation";
import ChangePassword from "./changePassword";
import MyAddress from "./myAddress";
import Orders from "./myOrders";
import OrderDetails from "./order-details";

const Profile = () => {
  return (
    <section className="profile">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <Menu />
          </div>
          <div className="col-md-9">
            <Routes>
              <Route path="edit-information" element={<PersonalInfo />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="address" element={<MyAddress />} />
              <Route
                path="/my-orders/order-details/:id"
                element={<OrderDetails />}
              />
              <Route path="my-orders" element={<Orders />} />
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
