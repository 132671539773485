import { Formik } from "formik";
import * as Yup from "yup";
const SignupSchema = Yup.object().shape({
  currentpassword: Yup.string().min(8, "minimum 8 digts").required("Required"),
  newpassword: Yup.string().min(8, "minimum 8 digts").required("Required"),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref("newpassword"), null], "passwords doesn't match")
    .required("Required"),
});
const ChangePassword = () => {
  return (
    <div className="change-password">
      <h4>Change Your password</h4>
      <Formik
        validationSchema={SignupSchema}
        initialValues={{
          currentpassword: "",
          newpassword: "",
          confirmpassword: "",
        }}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className="main-form">
            <input
              className={errors.currentpassword ? "error" : ""}
              type="password"
              name="currentpassword"
              placeholder="Current Password"
              onChange={handleChange}
              value={values.currentpassword}
            />
            <span className="forget">Forget Your Password</span>
            <span className="span-error">
              {errors.currentpassword &&
                touched.currentpassword &&
                errors.currentpassword}
            </span>{" "}
            <input
              className={errors.newpassword ? "error" : ""}
              type="password"
              name="newpassword"
              placeholder="New Password"
              onChange={handleChange}
              value={values.newpassword}
            />
            <span className="span-error">
              {errors.newpassword && touched.newpassword && errors.newpassword}
            </span>
            <input
              className={errors.confirmpassword ? "error" : ""}
              type="password"
              name="confirmpassword"
              placeholder="Confirm Password"
              onChange={handleChange}
              value={values.confirmpassword}
            />
            <span className="span-error">
              {errors.confirmpassword &&
                touched.confirmpassword &&
                errors.confirmpassword}
            </span>
            <button type="submit" disabled={isSubmitting}>
              Save
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
