import jacket from "../assets/jacket.png";
import "./Category.scss";
const CategoryType = ({
  image,
  titleEn,
  titleAr,
  id,
  handleCategory,
  language,
  index,
  activate,
}) => {
  return (
    <>
      <div
        onClick={() => {
          handleCategory(id, index);
        }}
        className="category"
      >
        <div className={`circle `}>
          <img src={image} alt="" />
        </div>
        <span
          className={`text-center ${activate}`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {language ? titleEn : titleAr}
        </span>
      </div>
    </>
  );
};

export default CategoryType;
