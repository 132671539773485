import higher from "../../assets/higher.png";
import { Formik, Field, Form, useFormik } from "formik";
import Checkout from "./checkoutItem";
import "./index.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import border from "../../assets/border.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { apiLink } from "../../api";

// const token = localStorage.getItem("token");

// const headers = { Authorization: `Bearer ${token}` };
const DeleviryAddress = () => {
  const [dataAddress, setAddress] = useState([]);
  const [dataCities, setCities] = useState([]);
  const [active, setActive] = useState(false);
  const [selectedAddres, setSelectedAddres] = useState(null);
  const [selectAdaddressId, setSelectedAddressId] = useState(null);
  const globalTotal = useSelector((state) => state.cart.totalPrice);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    axios.get(`${apiLink}/api/allcity`, { headers }).then((response) => {
      console.log(response.data);
      setCities(response.data);
    });
    axios
      .get(`${apiLink}/api/address/user/view`, { headers })
      .then((response) => {
        console.log(response.data);
        setAddress(response.data);
      });
  }, [active]);

  const handleAddAddress = () => {
    setActive(!active);
  };

  const handleRadioButton = (data, id) => {
    console.log(data);
    setSelectedAddres(data);
    setSelectedAddressId(id);
    localStorage.setItem("city", data.city);
    localStorage.setItem("addressId", id);
  };
  const checkoutHandle = () => {
    console.log(selectedAddres, selectAdaddressId);
    navigate("/checkout");
  };
  return (
    <section className="delivery-address">
      <div className="container">
        <p className="head-line">Shipping cart</p>
        <img className="higher" src={higher} alt="" />
        <p className="second-title">Delivery Address</p>
        <div className="row">
          <div className="col-md-7">
            <form>
              <div role="group" aria-labelledby="my-radio-group">
                {dataAddress.length > 0
                  ? dataAddress.map((item) => {
                      return (
                        <div key={item.id} className="box">
                          <label className="address-title">
                            <span>{item.city}</span>
                            <span>{item.address}</span>
                          </label>

                          <input
                            type="radio"
                            name="address"
                            value={item}
                            onClick={() => {
                              handleRadioButton(item, item.id);
                            }}
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>
            </form>
            {/* <Formik
              initialValues={{
                picked: "",
              }}
              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                alert(JSON.stringify(values, null, 2));
              }}
            >
              {({ values }) => (
                <>
                  <Form>
                    <div role="group" aria-labelledby="my-radio-group">
                      {dataAddress.map((item) => {
                        return (
                          <div key={item.id} className="box">
                            <label className="address-title">
                              {item.address}
                              <span>{item.street_name}</span>
                              <span>{item.flat_number}</span>
                            </label>

                            <Field
                              type="radio"
                              name="picked"
                              value={item.address}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div>Picked: {values.picked}</div>
                  </Form>
                </>
              )}
            </Formik> */}

            <button
              style={{ marginBottom: "1rem" }}
              onClick={handleAddAddress}
              className={`add-address ${active ? "hidden" : ""}`}
            >
              Add New Address
            </button>
            <Formik
              initialValues={{
                address: "",
                city: "",
                street_name: "",
                building_number: "",
                flat_number: "",
              }}
              onSubmit={(values) => {
                const token = localStorage.getItem("token");
                const headers = { Authorization: `Bearer ${token}` };
                console.log(values);
                axios.post(`${apiLink}/api/address/user/create`, values, {
                  headers,
                });
                setActive(!active);
              }}
            >
              {({ values }) => (
                <Form className={active ? "" : "hidden"}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-12">
                        <Field
                          type="text"
                          name="address"
                          className="input-address inputs"
                          placeholder="Address"
                        />
                      </div>
                      <div className="col-lg-6">
                        {/* <Field
                          type="text"
                          name="city"
                          className="input-address inputs"
                          placeholder="City"
                        /> */}
                        <Field
                          name="city"
                          component="select"
                          className="input-address inputs"
                        >
                          {/* {dataCities.map((item) => {
                            return <option value="red">Red</option>;
                          })} */}
                          {dataCities
                            ? dataCities.map((item) => {
                                return (
                                  <option key={item.id} value={item.city}>
                                    {item.city}
                                  </option>
                                );
                              })
                            : ""}
                        </Field>
                      </div>
                      <div className="col-lg-6">
                        <Field
                          type="text"
                          name="street_name"
                          className="input-address inputs"
                          placeholder="Street"
                        />
                      </div>
                      <div className="col-lg-6">
                        <Field
                          type="text"
                          name="building_number"
                          className="input-address inputs"
                          placeholder="Building Number"
                        />
                      </div>
                      <div className="col-lg-6">
                        <Field
                          type="text"
                          name="flat_number"
                          className="input-address inputs"
                          placeholder="Flat Number"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: "end" }} className="col-lg-12">
                    <button type="submit" className="add-new-address">
                      Add
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          {/*<Checkout url={"checkout"} buttonTitle={`NEXT`} />*/}
          <div className="col-md-3 cart-checkout">
            <h4 className="text-center">order summary</h4>
            <hr className="hr-title" />
            <div className="subtotal set-flex">
              <h5>subtotal</h5>
              <span>{globalTotal} EGP</span>
            </div>
            <div className="delivery  set-flex">
              <h5>delivery fee</h5>
              <span>22 EGP</span>
            </div>
            <div className="promocode  set-flex">
              <input placeholder="promocode" type="text" />
              <button>Apply</button>
            </div>
            <img src={border} alt="" />
            <div className="total set-flex">
              <h5>Total</h5>
              <span>{globalTotal} EGP</span>
            </div>
            {/*<Link to={`/${url}`} className="checkout">*/}
            {/*    NEXT*/}
            {/*</Link>*/}
            <button className="checkout" onClick={checkoutHandle}>
              NEXT
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeleviryAddress;
