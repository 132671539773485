import { Formik } from "formik";
import * as Yup from "yup";
import {apiLink} from "../../api";
import axios from "axios";

const SignupSchema = Yup.object().shape({
  user_name: Yup.string().required("Required"),
  // phone: Yup.string().required("Required"),
  // email: Yup.string().min(8, "minimum 8 digts").required("Required"),
});
const PersonalInfo = () => {
  return (
    <div className="personal-info">
      <div className="title">
        <h4>Change Your Personal Information</h4>
        <Formik
          validationSchema={SignupSchema}
          initialValues={{
            user_name: "",

          }}
          onSubmit={(values) => {
              const token = localStorage.getItem("token");
              const headers = { Authorization: `Bearer ${token}` };
            console.log(values);
            axios.post(`${apiLink}/api/editprofile`, values , {headers}).then((response)=>{
                console.log(response)
            })

          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} className="main-form">
              <input
                className={errors.user_name ? "error" : ""}
                type="text"
                name="user_name"
                placeholder="username"
                onChange={handleChange}
                value={values.name}
              />
              <span className="span-error">
                {errors.user_name && touched.user_name && errors.user_name}
              </span>{" "}

              <button type="submit" disabled={isSubmitting}>
                Save
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PersonalInfo;
