import "./index.scss";
import HeaderMain from "./Header";
import Categories from "./Categories";
import MobileApp from "./mobileapp";
import BestSeller from "./Bestseller";
import NewArrivals from "./NewArrivals";
import { useSelector } from "react-redux";
const LandingPage = () => {
  const globalState = useSelector((state) => state);

  return (
    <>
      <HeaderMain />
      {/*<Categories />*/}
      {/*<NewArrivals />*/}
      <BestSeller />
      <MobileApp />
    </>
  );
};

export default LandingPage;
