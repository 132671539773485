import "./forms.scss";
import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
const SignupSchema = Yup.object().shape({
  phone: Yup.string().required("Required"),
});
const ForgetBox = () => (
  <div>
    <Formik
      validationSchema={SignupSchema}
      initialValues={{
        phone: "",
      }}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className="main-form">
          <input
            className={errors.phone ? "error" : ""}
            type="number"
            name="phone"
            placeholder="Phone number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            pattern="/^01[0125][0-9]{8}$/"
          />
          <span className="span-error">
            {errors.phone && touched.phone && errors.phone}
          </span>

          <button type="submit" disabled={isSubmitting}>
            create account
          </button>
        </form>
      )}
    </Formik>
  </div>
);

export default ForgetBox;
