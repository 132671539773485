import React, { useState, useEffect } from "react";
import arrow from "../../assets/higher.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import classes from "./index.module.scss";
import productRate from "../../assets/product-rate.png";
import wishIcon from "../../assets/wish-icon.jpg";
import { useParams } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify-newtest";
// import ShippingInfoPopUp from "./popup";
import PopUp from "./popup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addCart } from "../../redux-state/reducers";
import { apiLink } from "../../api";
import { useSelector } from "react-redux";
import "./style.css";
import PopUpSize from "./popup-2";
import ReactLoading from "react-loading";
const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState();
  const [productImage, setProductImage] = useState();
  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);
  const [sizes, setSizes] = useState();
  // for order
  const [selectedSizeId, setSelectedSizeId] = useState(null);
  const [selectedColorId, setSelectedColorId] = useState(null);

  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  // for total
  // for active selected
  const [activeSizeIndex, setActiveSizeIndex] = useState(null);
  const [activeColrIndex, setActiveColorIndex] = useState(null);
  // helpers functions
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const languageState = useSelector((state) => state.language.english);
  useEffect(() => {
    axios.get(`${apiLink}/api/productdetails/${id}`).then((response) => {
      setProduct(response.data);
      setSizes(response.data[0].color[0].size);
      console.log(response.data);
    });
  }, []);

  const handlePopup = () => {
    setActive(!active);
  };
  const handleTimes = () => {
    setActive(false);
  };
  const handleTimes2 = () => {
    setActive2(false);
  };
  const hadnlePopupScreen = () => {
    setActive(false);
  };
  const handleColorImage = (item, index) => {
    console.log(item);
    setProductImage(item.image[0].image);
    setSizes(item.size);
    setSelectedColorId(item.id);
    setActiveSizeIndex(null);
    setActiveColorIndex(index);
    setSelectedColor(item.color_code);
  };
  const haddleSize = (item, index) => {
    console.log(item);
    setSelectedSizeId(item.id);
    setActiveSizeIndex(index);
    setSelectedSize(item.size);
  };
  const handlePopup2 = () => {
    setActive2(!active2);
    console.log(product);
  };
  const handleWishList = (id) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    // axios
    //   .post(`${apiLink}/wishlist/add/${id}`, {}, { headers })
    //   .then((response) => {
    //     console.log(response);
    //   });
    axios
      .post(`${apiLink}/api/wishlist/add/${id}`, {}, { headers })
      .then((response) => {
        console.log(response);
      });
    console.log(id);
  };
  const checkImages = () => {
    if (productImage) {
      return (
        <div className={classes.card_image} style={{ textAlign: "center" }}>
          <div className={classes.box_image}>
            <div
              style={{ width: "500px", height: "513px" }}
              className={classes.max}
            >
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "Wristwatch by Ted Baker London",
                    isFluidWidth: true,
                    src: productImage,
                    width: 400,
                    height: 400,
                  },
                  largeImage: {
                    isFluidWidth: true,
                    src: productImage,
                    width: 1600,
                    height: 1600,
                  },
                }}
              />
            </div>
            <div style={{ height: "513px" }} className={classes.small}>
              <img src={productImage} style={{ width: "366px" }} alt="" />
            </div>
          </div>
        </div>
      );
    } else if (product) {
      return (
        <div className={classes.card_image} style={{ textAlign: "center" }}>
          <div className={classes.box_image}>
            <div
              style={{ width: "500px", height: "513px" }}
              className={classes.max}
            >
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "Wristwatch by Ted Baker London",
                    isFluidWidth: true,
                    src: product[0].color[0].image[0].image,
                    width: 400,
                    height: 400,
                  },
                  largeImage: {
                    isFluidWidth: true,
                    src: product[0].color[0].image[0].image,
                    width: 1600,
                    height: 1600,
                  },
                }}
              />
            </div>
            <div style={{ height: "513px" }} className={classes.small}>
              <img
                src={product[0].color[0].image[0].image}
                style={{ width: "366px" }}
                alt=""
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const checkColors = () => {
    if (product) {
      return product[0].color.map((item, index) => {
        const activate = activeColrIndex === index ? "active-color" : "";
        return (
          <span
            onClick={() => {
              handleColorImage(item, index);
            }}
            key={item.id}
            style={{
              backgroundColor: item.color_code,
              border: "1px solid gray",
              cursor: "pointer",
            }}
            className={activate}
          ></span>
        );
      });
    }
  };

  const checkTitle = () => {
    if (product) {
      return <h5>{languageState ? product[0].en_name : product[0].ar_name}</h5>;
    }
  };

  const checkDescription = () => {
    if (product) {
      return (
        <p>
          {languageState
            ? product[0].en_description
            : product[0].ar_description}
        </p>
      );
    }
  };

  const priceCheck = (discount, price) => {
    if (discount === null || discount == 0) {
      return (
        <div className={classes.headline_details}>
          {checkTitle()}
          <h5>({price} EGP)</h5>
        </div>
      );
    } else {
      const myDiscount = discount / 100;
      const calcuteDiscount = price * myDiscount;
      const totalPrice = price - calcuteDiscount;
      return (
        <>
          <div className={classes.product.price}>
            <div className={classes.headline_details}>
              {checkTitle()}

              <h5>({totalPrice} EGP)</h5>
            </div>
            <div className={classes.subhead}>
              <div className={classes.rete}>
                <img src={productRate} alt="" />
              </div>
              <span>
                <h5>({price} EGP)</h5>
              </span>
            </div>
          </div>
        </>
      );
    }
  };

  const handleCart = ([product]) => {
    if (localStorage.getItem("token") != null) {
      if (activeSizeIndex != null) {
        dispatch(
          addCart({
            productId: product.id,
            sizeId: selectedSizeId,
            colorId: selectedColorId,
            color: selectedColor,
            size: selectedSize,
            price: product.price,
            discount: product.discount,
            productImage: productImage,
            productTitle: product,
            product_en_name: product.en_name,
            product_ar_name: product.ar_name,
            quantity: 1,
          })
        );
      } else {
        alert("sorry you should choose the size");
      }
    } else {
      navigate("/login");
    }
  };
if(product){
  return (
      <section className={classes.product}>
        <div className="container">
          <div className={classes.top}>
            <h5 className={classes.headline}>
              {" "}
              {languageState ? "Categories" : "الأقسام"}
            </h5>
            <img src={arrow} alt="" />
            <span> {languageState ? "Product info" : "معلومات المنتج"} </span>
          </div>
          <div className="row">
            <div className="col-lg-5" style={{ zIndex: 20 }}>
              {checkImages()}
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-5">
              {product ? (
                  product[0].available === 0 ? (
                      <span style={{ marginLeft: 0 }}>Out of stock</span>
                  ) : (
                      ""
                  )
              ) : (
                  ""
              )}
              <div className={classes.product_details}>
                {product ? (
                    priceCheck(product[0].discount, product[0].price)
                ) : (
                    <h2>Loading</h2>
                )}

                <div className={classes.descrition}>{checkDescription()}</div>
                <div className={classes.colors}>
                  <h3> {languageState ? "Color" : "اللون"}</h3>
                  {checkColors()}
                </div>
                <div className={classes.size}>
                  <h3> {languageState ? "Size" : "المقاس"}</h3>
                  {sizes
                      ? sizes.map((item, index) => {
                        const activate =
                            activeSizeIndex === index ? "active-size" : "";
                        return (
                            <span
                                key={item.id}
                                onClick={() => {
                                  haddleSize(item, index);
                                }}
                                className={activate}
                            >
                          {item.size}
                        </span>
                        );
                      })
                      : ""}
                </div>

                <hr style={{ marginTop: "2rem" }} />
                <div
                    onClick={() => {
                      handlePopup("shipping");
                    }}
                    className={classes.shippnig}
                >
                  <div className="left">
                    <svg
                        id="Icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                    >
                      <rect
                          id="Area"
                          width="20"
                          height="20"
                          fill="#fcfcfc"
                          opacity="0"
                      />
                      <g
                          id="Icon-2"
                          data-name="Icon"
                          transform="translate(1.29 2.499)"
                      >
                        <path
                            id="Path"
                            d="M17.5,6.667V17.5H2.5V6.667"
                            transform="translate(-1.29 -2.499)"
                            fill="none"
                            stroke="#ffb31c"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <rect
                            id="Rect"
                            width="18.333"
                            height="4.167"
                            transform="translate(-0.457 0.001)"
                            fill="none"
                            stroke="#ffb31c"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <line
                            id="Line"
                            x2="3.333"
                            transform="translate(7.043 7.501)"
                            fill="none"
                            stroke="#ffb31c"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                      </g>
                    </svg>
                    <h5 style={{ display: "inline", marginLeft: "1rem" }}>
                      {languageState ? "Shipping info" : "معلومات الشحن"}
                    </h5>
                  </div>

                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                  >
                    <path
                        id="Icon_awesome-question-circle"
                        data-name="Icon awesome-question-circle"
                        d="M18.563,9.563a9,9,0,1,1-9-9A9,9,0,0,1,18.563,9.563ZM9.8,3.538a4.7,4.7,0,0,0-4.23,2.314.436.436,0,0,0,.1.59L6.932,7.4a.435.435,0,0,0,.6-.077c.648-.822,1.093-1.3,2.08-1.3.741,0,1.658.477,1.658,1.2,0,.543-.449.823-1.181,1.233-.854.479-1.983,1.074-1.983,2.564v.145a.436.436,0,0,0,.435.435h2.032a.436.436,0,0,0,.435-.435v-.048c0-1.033,3.019-1.076,3.019-3.871C14.033,5.135,11.85,3.538,9.8,3.538Zm-.242,9a1.669,1.669,0,1,0,1.669,1.669A1.671,1.671,0,0,0,9.563,12.538Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#999"
                    />
                  </svg>
                </div>
                <hr />
                <div onClick={handlePopup2} className={classes.size_guide}>
                  <div className="left">
                    <svg
                        id="Icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                    >
                      <rect
                          id="Area"
                          width="20"
                          height="20"
                          fill="none"
                          opacity="0"
                      />
                      <g
                          id="Icon-2"
                          data-name="Icon"
                          transform="translate(1.29 2.499)"
                      >
                        <path
                            id="Path"
                            d="M4.167,13.333V7.5H15.833V1.667H4.167L15.833,13.333H10m-5.833,0L10,19.167V13.333m-5.833,0H10"
                            transform="translate(-1.29 -2.499)"
                            fill="none"
                            stroke="#ffb31c"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                      </g>
                    </svg>
                    <h5 style={{ display: "inline", marginLeft: "1rem" }}>
                      {languageState ? "Size guide" : "دليل المقاسات"}
                    </h5>
                  </div>
                  <div className="right">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                    >
                      <path
                          id="Icon_awesome-question-circle"
                          data-name="Icon awesome-question-circle"
                          d="M18.563,9.563a9,9,0,1,1-9-9A9,9,0,0,1,18.563,9.563ZM9.8,3.538a4.7,4.7,0,0,0-4.23,2.314.436.436,0,0,0,.1.59L6.932,7.4a.435.435,0,0,0,.6-.077c.648-.822,1.093-1.3,2.08-1.3.741,0,1.658.477,1.658,1.2,0,.543-.449.823-1.181,1.233-.854.479-1.983,1.074-1.983,2.564v.145a.436.436,0,0,0,.435.435h2.032a.436.436,0,0,0,.435-.435v-.048c0-1.033,3.019-1.076,3.019-3.871C14.033,5.135,11.85,3.538,9.8,3.538Zm-.242,9a1.669,1.669,0,1,0,1.669,1.669A1.671,1.671,0,0,0,9.563,12.538Z"
                          transform="translate(-0.563 -0.563)"
                          fill="#999"
                      />
                    </svg>
                  </div>
                </div>
                <hr />
                <div className={classes.options}>
                  {product ? (
                      product[0].available === 0 ? (
                          <button className={classes.disabled}>
                            {languageState ? "Add to cart" : "أضف إلى السلة"}
                          </button>
                      ) : (
                          <button
                              onClick={() => {
                                handleCart(product);
                              }}
                          >
                            {languageState ? "Add to cart" : "أضف إلى السلة"}
                          </button>
                      )
                  ) : (
                      ""
                  )}

                  <img
                      src={wishIcon}
                      alt=""
                      onClick={() => {
                        handleWishList(product[0].id);
                      }}
                  />
                </div>
              </div>
            </div>

            <PopUp
                active={active}
                handletimes={handleTimes}
                hadnlePopupScreen={hadnlePopupScreen}
            />
            <PopUpSize
                active={active2}
                handletimes={handleTimes2}
                hadnlePopupScreen={hadnlePopupScreen}
                img={product ? product[0].size_guide : ""}
            />
          </div>
        </div>
      </section>
  );
}else{
  return <ReactLoading type={'bubbles'} color={'#601c7e'} height={'100vh'} width={200} className={classes.loading} />
}

};

export default ProductDetails;
