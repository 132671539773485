import classes from "./popup.module.scss";
import times from "../../assets/times.png";
const PopUp = ({ active, handletimes, hadnlePopupScreen }) => {
  return (
    <section
      className={`${classes.popup} ${active ? classes.active : classes.hidden}`}
      onClick={hadnlePopupScreen}
    >
      <div className={classes.shipping_popup}>
        <div className={classes.top}>
          <h4 className={classes.title}>Shipping Info</h4>
          <img
            onClick={handletimes}
            className={classes.times}
            src={times}
            alt=""
          />
        </div>
        <div className="content">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perspiciatis
          optio alias nisi aspernatur magnam! Sint optio dolores maxime,
          voluptatum unde quibusdam cupiditate ad veniam est? Dignissimos itaque
          quasi nam voluptate.
        </div>
      </div>
    </section>
  );
};

export default PopUp;
