import axios from "axios";
import { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { apiLink } from "../../api";
import arrow from "../../assets/head-arrow.png";
import OrderDetailsItem from "../../components/order-details-item";
import rate from '../../assets/rate-button.png'
const OrderDetails = () => {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };
  const [products, setProducts] = useState([]);
  const [order, setOrder] = useState();
  const [address,setAddress] = useState()
  let navigate = useNavigate()
  useEffect(() => {
    axios
      .get(`${apiLink}/api/order/details/${id}`, { headers })
      .then((response) => {
        console.log(response)
        setProducts(response.data.order.order_product);
        setOrder(response.data.order);
        setAddress(response.data.address)

      });
  }, []);
  const handleCancelOrder = (order_id) =>{
    console.log(order_id);
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    axios.post(`${apiLink}/api/order/destroy/${order_id}`, {},{headers}).then((response)=>{
      console.log(response)
      navigate('/profile/my-orders')
    })
  }
// console.log(order)
  const theDate = new Date( order ? order.created_at : '');
  let formatDate = theDate.toLocaleString("en-us");
  const checkStatus = () => {
    if(order){
      switch (order.status){
        case 0 :
          return <p style={{ color: "red" }} className={'order_status'}>Pending</p>;
          break;
        case 1:
          return <p style={{ color: "#1492E6" }} className={'order_status'}>Processing </p>;
          break;
        case 2:
          return <p style={{ color: "#FF8000" }} className={'order_status'}>Shipped </p>;
          break;
        case 3:
          return <p style={{ color: "#0DB022" }} className={'order_status'}>Delivered </p>;
          break;
        case 4 :
          return <p style={{ color: "red" }} className={'order_status'}>Rejected </p>;
        default :
          return
      }
    }else{
      return
    }
  };
  const checkMessage = () =>{
    if (order){
      switch (order.status){
        case 0 :
          return <p className={'order_msg'}>Please Wait While Your Request Is Being Processed</p>
        break
        default :
          return <p className={'order_msg'}>We Hope You Are Happy By Your Dealing With Beneshty</p>
      }
    }
  }

  const checkButtons = ()=>{
    if(order){
      switch (order.status){
        case 3 :
          return  <button type="button" className={`btn btn-primary`} style={{padding :'.6rem 2.8rem' , backgroundColor:'#601C7E' , border:'0' , borderRadius : '10px'}}  data-bs-toggle="modal" data-bs-target="#exampleModal">
          <img src={rate} style={{width:'10%' , marginBottom:'3px'}}/>   RATE YOUR ORDER
          </button>
        break
        default :
          return  <button type="button" className={`btn cancel-button ${ order ? order.status !== 0 ? 'btn disabled' : '' : '' }`}  data-bs-toggle="modal" data-bs-target="#exampleModal">
            Cancle order
          </button>


      }
    }
  }

  const checkModel = () =>{
    if(order){
      switch (order.status){
        case 3 :
          return <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                      aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel"
                      style={{color: '#601C7E', fontWeight: 'bold'}}>cancel order</h5>
                  <p style={{textAlign: 'center'}}>are you sure to cancel this <br/> order ?</p>
                </div>
                <div className="modal-body">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                          style={{backgroundColor: '#E40000'}} onClick={() => {
                    handleCancelOrder(order.id)
                  }}>Yes , Cancel
                  </button>
                  <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                          style={{backgroundColor: '#F5F5F5', color: 'black'}}>No , Thanks
                  </button>
                </div>
              </div>
            </div>
          </div>
        break
        default :
          return <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                      aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel"
                      style={{color: '#601C7E', fontWeight: 'bold'}}>cancel order</h5>
                  <p style={{textAlign: 'center'}}>are you sure to cancel this <br/> order ?</p>
                </div>
                <div className="modal-body">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                          style={{backgroundColor: '#E40000'}} onClick={() => {
                    handleCancelOrder(order.id)
                  }}>Yes , Cancel
                  </button>
                  <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                          style={{backgroundColor: '#F5F5F5', color: 'black'}}>No , Thanks
                  </button>
                </div>
              </div>
            </div>
          </div>
      }
    }
  }

  return (
    <section className="order-details">
      <div className="container">
        <div className="top">
          <h6 className="headline">Orders</h6>
          <img src={arrow} alt="" />
          <h6>order details</h6>
        </div>
        <div className={'order-status-info'}>
         <div className={'left'}>
           <p className={'order_number'}>id : { order ? order.id : ''}</p>
           {checkStatus()}
           {/*<p className={'order_msg'}>Please Wait While Your Request Is Being Processed</p>*/}
           {checkMessage()}
         </div>
          <div className={'right'}>
            <p>{formatDate}</p>
          </div>
        </div>
        <div className="order-info">
          <div class="accordion" id="accordionExample">
            <div className="row">
              <div className="col-lg-12">
                <div className="accordion-item product-accordian">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Products
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {products
                        ? products.map((item) => {
                            return (
                              <OrderDetailsItem
                                key={item.id}
                                qty={item.quantity}
                                total={item.total}
                                product_id={item.product_id}
                                image={item.image}
                              />
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Location
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                    <p className={'location-des'}>{address?.address}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Payment
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <h5>By {order ? order.payment_type : ""}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Order Info
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="subtotal d-flex justify-content-between">
                        <h5 className="title">Sub Total</h5>
                        <span className="data">
                          {order ? order.sub_total : ""} EGP
                        </span>
                      </div>
                      <div className="delivery d-flex justify-content-between">
                        <h5 className="title">Delivery free</h5>
                        <span className="data">{order?.shipping} EGP</span>
                      </div>
                      <div className="total d-flex justify-content-between">
                        <h5 className="title">Total</h5>
                        <span className="data">
                          {order ? order.total_price : ""} EGP
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 text-center  btn">
                {/*<button className={`cancel-button ${ order ? order.status !== 0 ? 'btn disabled' : '' : '' }`} onClick={()=>{handleCancelOrder(order.id)}}>Cancle order</button>*/}
                {/*<button type="button" className={`btn cancel-button ${ order ? order.status !== 0 ? 'btn disabled' : '' : '' }`}  data-bs-toggle="modal" data-bs-target="#exampleModal">*/}
                {/*  Cancle order*/}
                {/*</button>*/}
                {checkButtons()}
              </div>



              {checkModel()}

            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default OrderDetails;
