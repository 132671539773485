import "./index.scss";
import emptyLogo from "../../assets/empty-logo.png";

import WishListItem from "../../components/wishListItem";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiLink } from "../../api";
const token = localStorage.getItem("token");
const headers = { Authorization: `Bearer ${token}` };
const WishList = () => {
  const [proudcts, setProducts] = useState([]);
  useEffect(() => {
    axios.get(`${apiLink}/api/wishlist/view`, { headers }).then((response) => {
      setProducts(response.data);
    });
  }, []);
  console.log(proudcts);
  const wishListEmpty = () => {
    return (
      <section className="empty">
        <div className="empty-wishlist">
          <img src={emptyLogo} alt="" />
        </div>
        <h2 className="text-center">Your Wishlist Is Empty Now</h2>
      </section>
    );
  };
  return (
    <section className="wishlist">
      <div className="container">
        <div className="row ">
          {proudcts.length > 0
            ? proudcts.map((item) => {
                return (
                  <div key={item.id} className="col-lg-3 col-md-6">
                    <WishListItem
                      image={item.color[0].image[0].image}
                      id={item.id}
                      price={item.price}
                      discount={item.discount}
                    />
                  </div>
                );
              })
            : wishListEmpty()}
        </div>
      </div>
    </section>
  );
};

export default WishList;
