import { useNavigate } from "react-router-dom";
import orderImage from "../assets/order-image.png";
import twoArrows from "../assets/two-arrows.png";
import "./orderItem.scss";

const OrderItem = ({ date, id, status , image }) => {
  let navigate = useNavigate();
  const theDate = new Date(date);
  let formatDate = theDate.toLocaleString("en-us");

  const checkStatus = () => {
    switch (status){
      case 0 :
        return <p style={{ color: "red" }}>Pending</p>;
      break;
      case 1:
        return <p style={{ color: "#1492E6" }}>Processing </p>;
      break;
      case 2:
        return <p style={{ color: "#FF8000" }}>Shipped </p>;
      break;
      case 3:
        return <p style={{ color: "#0DB022" }}>Delivered </p>;
      break;
      case 4 :
        return <p style={{ color: "red" }}>Rejected </p>;
      default :
        return
    }

  };
  const handleClickItem = () => {
    navigate(`order-details/${id}`);
  };
  return (
    <div className="item" onClick={handleClickItem}>
      <div className="left">
        <img className="order_image" src={image} alt="" />
      </div>
      <div className="right">
        <div className="left-2">
          <p>ID : {id}</p>
          {checkStatus()}
        </div>
        <div className="right-2">
          <p>{formatDate}</p>
          <div className="image-placeholder">
            <img src={twoArrows} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
