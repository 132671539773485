import detailsImage from ".././assets/order-details-image.png";
import "./order-details.scss";
const OrderDetailsItem = ({ qty, total, product_id, image }) => {
  return (
    <div className="order-item" key={product_id}>
      <div className="left">
        <img className="image" src={image} alt="" />
      </div>
      <div className="right">
        <div className="title">
          <p>{product_id}</p>
          <span className="quantity">{qty} X</span>
        </div>
        <div className="price">
          <p>{total} EGP</p>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsItem;
