import classes from "./popup.module.scss";
import times from "../../assets/times.png";
const PopUpSize = ({ active, handletimes, hadnlePopupScreen, img }) => {
  return (
    <section
      className={`${classes.popup} ${active ? classes.active : classes.hidden}`}
      onClick={hadnlePopupScreen}
    >
      <div className={classes.shipping_popup}>
        <div className={classes.top}>
          <h4 className={classes.title}>Shipping Info</h4>
          <img
            onClick={handletimes}
            className={classes.times}
            src={times}
            alt=""
          />
        </div>
        <div className="content">
          <img src={img} alt="size_guide" />
        </div>
      </div>
    </section>
  );
};

export default PopUpSize;
