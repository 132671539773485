import "./notification.scss";
import times from "../assets/times.png";
import noNotification from "../assets/no-notification.png";
const Notification = ({ valid, handleWindow, handleOustideClick }) => {
  return (
    <section className={`notofication ${valid ? "hidden" : ""}`}>
      <div className="box">
        <div className="title">
          <h4>Notification</h4>
          <img onClick={handleWindow} src={times} />
        </div>
        <div className="content">
          <img src={noNotification} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Notification;
