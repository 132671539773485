import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Slider from "./Slider";
import model1 from "../src/assets/model.png";
import Item from "./components/item";
import BoxTest from "./components/test";
import TestPagination from "./test-pagination";
import { Provider } from "react-redux";
import { store } from "./redux-state/store";
import OrderDetailsItem from "./components/order-details-item";
import Slideeeer from "./Slider";
import ReactLoading from "react-loading";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>

      {/*<Slideeeer/>*/}
      {/* <BoxTest /> */}
      {/* <TestPagination /> */}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
