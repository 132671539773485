import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { motion } from "framer-motion";
import { apiLink } from "../../api";
import ReactLoading from "react-loading";
// import "./styles.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
const HeaderMain = () => {
  const [images, setImages] = useState();
  const globalLanguage = useSelector((state) => state.language.english);
  useEffect(() => {
    axios.get(`${apiLink}/api/sliders/view`).then((response) => {
      setImages(response.data);
      console.log(response.data);
    });
  }, []);
  return (
    <header>
      <div className="container">
      </div>
      <div className="header-slider">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          // autoplay={{ delay: 2500 }}
          dir={`${globalLanguage === true ? "ltr" : "rtl"}`}
          // dir="rtl"
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {images
            ? images.map((image) => {
                return (
                  <SwiperSlide key={image.en_text}>
                    <div>
                      <img
                          className={'img-fluid'}
                        style={{ height: "90vh" }}
                        src={image.image}
                        alt=""
                      />

                      <div className="bottom">
                        <motion.div
                          initial={{ opacity: 0, y: -200 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{
                            type: "tween",
                            duration: 1,
                            delay: 0.5,
                          }}
                          className="content"
                        >
                          {/*<h1>NEW SALE</h1>*/}
                          {/*<p className="text-center">*/}
                          {/*  UP TO <span>40%</span>*/}
                          {/*</p>*/}
                          <span className={'slider-title'}>
                            {globalLanguage ? image.en_text : image.ar_text}
                          </span>
                        </motion.div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            : <ReactLoading type={'bubbles'} color={'#601c7e'} height={'100vh'} width={200} className={'loading'} />}
          ;
        </Swiper>
      </div>
    </header>
  );
};

export default HeaderMain;
