import user from "../assets/user.png";
import cart from "../assets/cart-icon.png";
import notification from "../assets/notification.png";
import world from "../assets/world.png";
import logo from "../assets/logo.png";
import search from "../assets/search2.png";
import "./Nav.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Notification from "./notification";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLanguage } from "../redux-state/reducers";
import { apiLink } from "../api";
import axios from "axios";
import wishList from "../assets/wishlist.png";
const Nav = () => {
  const [valid, setValid] = useState(true);
  const [text, setText] = useState();
  const [data, setData] = useState();
  const [suggesion, setSuggesion] = useState();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.cart.cartProducts);
  // const globalState = JSON.parse(localStorage.getItem("cart"));
  const products = JSON.parse(localStorage.getItem("cart"));
  const languageState = useSelector((state) => state.language.english);
console.log(globalState)
  let navigate = useNavigate();
  const handleWindow = () => {
    setValid(!valid);
  };
  const handleOustideClick = () => {
    setValid(false);
  };
  useEffect(() => {
    if (!languageState) {
      document.getElementById("root").classList.add("rtl");
      document.documentElement.lang = "ar";
    } else {
      document.getElementById("root").classList.remove("rtl");
      document.documentElement.lang = "en";
    }
  }, [languageState]);

  useEffect(() => {
    axios.post(`${apiLink}/api/search`).then((response) => {
      console.log(response.data.data);
      setData(response.data.data);
    });
  }, []);

  const handleLanguage = () => {
    dispatch(changeLanguage());
  };
  const handleProfile = () => {
    if (localStorage.getItem("token") === null) {
      navigate(`/login`);
    } else {
      navigate(`/profile/edit-information`);
    }
  };
  const handleLocal = () => {
    localStorage.removeItem("typeId");
  };
  const changeHandler = (text) => {
    let matchesData = [];
    if (text.length > 0) {
      matchesData = data.filter((item) => {
        const regex = new RegExp(`${text}`, "gi");
        return item.en_name.match(regex);
      });
    }

    setSuggesion(matchesData);
    setText(text);
  };
  const handleClick = (text) => {
    setText(text);
    setSuggesion([]);
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light  sticky-top">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="options">
          {/* <input
            type="text"
            value={text}
            onChange={(e) => {
              changeHandler(e.target.value);
            }}
          />
          <br />
          <div className="container-search">
            {suggesion &&
              suggesion.map((suggesion, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      handleClick(suggesion.en_name);
                    }}
                    className="suggession"
                    style={{ zIndex: 10 }}
                  >
                    {suggesion.en_name}
                  </div>
                );
              })}
          </div> */}

          <img src={search} alt="search" style={{cursor:'pointer'}} />
          <a  onClick={handleProfile} style={{cursor:'pointer'}}>
            <img src={user} alt="user" />
          </a>

          <Link to={"/wishlist"}>
            <img src={wishList} alt="user" />
          </Link>

          <Link
            to={"/shipping-cart"}
            style={{
              backgroundColor: "#F8f8f8",
              padding: "10px",
              borderRadius: "50%",
              position: "relative",
            }}
          >
            <img src={cart} alt="cart" style={{ zIndex: "10" }} />
            {globalState.length > 0 ? (
              <span
                style={{
                  position: "absolute",
                  left: "15px",
                  bottom: "26px",
                  backgroundColor: "#FFB31C",
                  padding: "4px 7px",
                  borderRadius: "30%",
                }}
                className="badge badge-light"
              >
                {globalState.length}
              </span>
            ) : (
              ""
            )}
          </Link>
          {/* <img
            style={{ cursor: "pointer" }}
            onClick={handleWindow}
            src={notification}
            alt="notification"
          /> */}
          <Notification
            className="notify"
            valid={valid}
            handleWindow={handleWindow}
            handleOustideClick={handleOustideClick}
          />
          <img src={world} alt="cart" onClick={handleLanguage} style={{cursor:'pointer'}} />
        </div>

        <div className="logo">
          {/* <motion.img src={logo} animate={{ translateX: "-100" }} /> */}
          <img src={logo} alt="logo" />
        </div>

        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <NavLink
              className="nav-link"
              id="home"
              aria-current="page"
              to={"/"}
            >
              {languageState ? "Home" : "الرئيسية"}
            </NavLink>

            <NavLink
              className="nav-link"
              to={"/category"}
              onClick={handleLocal}
            >
              {languageState ? "Categories" : "الأقسام"}
            </NavLink>
            <NavLink className="nav-link" to={"/branches"}>
              {languageState ? "Branches" : "فروعنا"}
            </NavLink>

            {/* <NavLink className="nav-link" to={"/wishlist"}>
              {languageState ? "Wish list" : "القائمة المفضلة"}
            </NavLink> */}
            <NavLink className="nav-link" to={"/contact-us"}>
              {languageState ? "Contact Us" : "تواصل معنا"}
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
