import {createSlice} from "@reduxjs/toolkit";

export const cart = createSlice({
    name: "cart", initialState: {
        cartProducts: JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : [],
        totalPrice: 0,
        order: {},
    }, reducers: {
        addCart: (state, payload) => {
            const element = state.cartProducts.find((item) => item.productId === payload.payload.productId && item.colorId === payload.payload.colorId && item.sizeId === payload.payload.sizeId);


            if (element) {
                element.quantity += 1

            } else {
                state.cartProducts.push(payload.payload);
            }

            localStorage.setItem("cart", JSON.stringify(state.cartProducts));
        }, removeCart: (state, payload) => {

            const index = state.cartProducts.findIndex((item) => item.productId === payload.payload.id && item.colorId === payload.payload.colorId && item.sizeId === payload.payload.sizeId);

            if (index !== -1) state.cartProducts.splice(index, 1);
            state.totalPrice = 0;
            localStorage.setItem("cart", JSON.stringify(state.cartProducts));
        }, sumTotal: (state, payload) => {
            state.totalPrice = payload.payload;
        }, changeQuantityPlus: (state, payload) => {

            const index = state.cartProducts.findIndex((item) => item.productId === payload.payload.id && item.colorId === payload.payload.colorId && item.sizeId === payload.payload.sizeId);
            if (index !== -1) state.cartProducts[index].quantity += 1;


            localStorage.setItem("cart", JSON.stringify(state.cartProducts));
        }, changeQuantityMinus: (state, payload) => {

            const index = state.cartProducts.findIndex((item) => item.productId === payload.payload.id && item.colorId === payload.payload.colorId && item.sizeId === payload.payload.sizeId);
            if (index !== -1) state.cartProducts[index].quantity -= 1;

            localStorage.setItem("cart", JSON.stringify(state.cartProducts));
        }, resetTotal: (state, payload) => {

            state.totalPrice = 0;
        }, resetProducts: (state, payload) => {

            state.cartProducts = []
        }
    },
});
export const language = createSlice({
    name: "language", initialState: {english: true}, reducers: {
        changeLanguage: (state, payload) => {
            state.english = !state.english;
        },
    },
});
export const {
    addCart, removeCart, sumTotal, changeQuantityPlus, changeQuantityMinus, resetTotal, resetProducts
} = cart.actions;
export const {changeLanguage} = language.actions;
