import {NavLink, Outlet} from "react-router-dom";
import {useEffect, useState} from "react";
import OrderItem from "../../components/orderItem";
import axios from "axios";
import {apiLink} from "../../api";
import ReactLoading from "react-loading";
const choices = [{id: 1, status_code: null ,  title: "All"}, {id: 2, status_code: 0 , title: "Pending"}, {id: 3, status_code: 1, title: "Processing"}, {
    id: 4, status_code: 2, title: "Shipped"
}, {id: 5, status_code: 3 , title: "Delivered"}, {id: 6, status_code: 4, title: "Rejected"},];
const Orders = () => {
    const [myIndex, setIndex] = useState(0);
    const [orders, setOrders] = useState();
    const [status, setStatus] = useState(0)
    const token = localStorage.getItem("token");
    const headers = {Authorization: `Bearer ${token}`};
    const handleClick = (i, status_code) => {
        setIndex(i);
        setOrders(null)
        console.log(status_code)
        if(status_code !== null){
            axios.get(`${apiLink}/api/order/filter/${status_code}`,{headers}).then((response)=>{
                setOrders(response.data)
            })
        }else{
            axios.get(`${apiLink}/api/order/view`,{headers}).then((response)=>{
                setOrders(response.data)
            })
        }
    };

    useEffect(() => {
        if (localStorage.getItem("token") !== null) {
            const token = localStorage.getItem("token");
            const headers = {Authorization: `Bearer ${token}`};
            axios.get(`${apiLink}/api/order/view`, {headers}).then((response) => {
                console.log(response.data);
                setOrders(response.data);
            });
        }
    }, []);


    return (<div className="orders">
        <div className="types">
            {choices.map((item, i) => {
                const activate = myIndex === i ? "active" : "";
                return (<a
                    className={activate}
                    href="#"
                    key={item.id}
                    // style={{fontWeight :'bold'}}
                    onClick={() => {
                        handleClick(i, item.status_code);
                    }}
                >
                    {item.title}
                </a>);
            })}
        </div>
        <div className="products text-center">
            {orders ? orders.map((order) => {
                return (<OrderItem
                    key={order.id}
                    date={order.created_at}
                    id={order.id}
                    status={order.status}
                    image={order.order_product[0].image}
                />);
            }) : <ReactLoading type={'bubbles'} color={'#601c7e'} height={'100vh'} width={200} className={'loading'} />}
            {/* <OrderItem />
        <OrderItem />
        <OrderItem /> */}
        </div>
    </div>);
};

export default Orders;
