import { useEffect, useState } from "react";
import Item from "../../components/item";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import "../../style/pagination.css";
import axios from "axios";
import { apiLink } from "../../api";
const NewArrivals = () => {
  const [pages, setPages] = useState("");
  const [products, setProducts] = useState("");
  const [info, setInfo] = useState("");
  const [url, setUrl] = useState(`${apiLink}/api/product/newarrivals`);
  const [data, setData] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url: url,
    }).then(response => {
      setPages(response.data.links);
      setProducts(response.data.data);
      setInfo(response.data);
    });
  }, [url]);
  const handlePagination = url => {
    setUrl(url);
  };
  return (
    <section className="new-arrivals">
      <div className="container">
        <h1>New Arrivals</h1>
        <div className="items-arriavls">
          <div className="row">
            {products ? (
              products.map(item => {
                return (
                  <div
                    key={item.id}
                    className="col-lg-3 col-md-4 col-12 row-item"
                  >
                    <Item
                      discount={item.discount}
                      price={item.price}
                      title={item.en_name}
                      image={item.color[0].image[0].image}
                      rate={item.rate}
                      id={item.id}
                    />
                  </div>
                );
              })
            ) : (
              <h2>Loading...</h2>
            )}
          </div>
        </div>
      </div>
      <>
        {/* <div>
          {products ? <h1>{products.length}</h1> : console.log("waiting")}
        </div> */}
        <div
          className="pagination-container"
          aria-label="Page navigation example"
        >
          <ul className="pagination">
            {pages
              ? pages.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={`page-item ${item.active ? "active" : ""} ${
                        item.url === null ? "disabled" : ""
                      } `}
                    >
                      <a
                        className="page-link "
                        onClick={() => {
                          handlePagination(item.url);
                        }}
                      >
                        {item.label === "&laquo; Previous" ? (
                          <FontAwesomeIcon
                            style={{ color: "#601c7e" }}
                            icon={faAngleLeft}
                          />
                        ) : item.label && item.label === "Next &raquo;" ? (
                          <FontAwesomeIcon
                            style={{ color: "#601c7e" }}
                            icon={faAngleRight}
                          />
                        ) : (
                          item.label
                        )}
                      </a>
                    </li>
                  );
                })
              : ""}
          </ul>
        </div>
      </>
    </section>
  );
};

export default NewArrivals;
