import { useEffect, useState } from "react";
import axios from "axios";
import BestSellerItem from "../../components/BestSellerItem";
import { apiLink } from "../../api";
const BestSeller = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios({
      method: "GET",
      url: `${apiLink}/api/product/bestseller`,
    }).then((response) => {
      setData(response.data);
    });
  }, []);
  return (
    <section className="bestseller">
      <div className="container">
        <h1>best seller</h1>
        <div className="row">
          {data.map((item) => {
            return (
              <div key={item.id} className="col-md-3 col-sm-12">
                <BestSellerItem
                  rate={item.rate}
                  price={item.price}
                  discount={item.discount}
                  main={"main-cart"}
                  title={item.en_name}
                  image={item.color[0].image[0].image}
                  id={item.id}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BestSeller;
