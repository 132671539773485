import Logo from "../../assets/logo-2.png";
import Login from "./Login";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input"
import ReactInputVerificationCode from 'react-input-verification-code';
import ReactCodeInput from 'react-verification-code-input';
import './verfication.scss';
import {useState} from "react";
import axios from "axios";
import {apiLink} from "../../api";

const SignIn = () => {
    const navigate = useNavigate();
    const [otpValues,setOtbValues] = useState(null)
    const handleCompeletedInputs = (values)=>{
        setOtbValues(values)
    }
    const handleveficationSubmit = ()=>{
        const submitValues = {phone:localStorage.getItem('phone-number') , otp :otpValues }
        axios.post(`${apiLink}api/check`,submitValues , {
            xsrfHeaderName: " X-CSRF-TOKEN",
        }).then((response)=>{
            console.log(response)
        })
    }
    return (
        <section className="sign-in">
            <div className="container">
                <div className="row ">
                    <div className="col describe">
                        <img src={Logo} alt="" />
                        <h2>verification</h2>
                        <p>
                            Enter the verification code we just <br/> sent you on your phone number
                        </p>

                    </div>
                    <div className="col" style={{padding:'5rem'}}>
                    <div className={'verfication'}>
                        <ReactCodeInput fields={5} onComplete={handleCompeletedInputs} className={'verfication'} />
                        <button className={`btn btn-verfiy ${otpValues ? '' : 'disabled'}`} onClick={handleveficationSubmit}>Verfiy</button>
                    </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default SignIn;
