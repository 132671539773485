import { useState, useEffect } from "react";
import model from "../../assets/model.png";
import deleteImg from "../../assets/delete.png";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { removeCart } from "../../redux-state/reducers";
import { sumTotal } from "../../redux-state/reducers";
// import { minusTotal } from "../../redux-state/reducers";
import { changeQuantityPlus } from "../../redux-state/reducers";
import { changeQuantityMinus } from "../../redux-state/reducers";
import { resetTotal } from "../../redux-state/reducers";
const CartItem = ({
  titleNameAr,
  titleNameEn,
  image,
  price,
  id,
  discount,
  index,
  sizeId,
  colorId,
  quantity,
}) => {
  const [total, setTotal] = useState(price);
  const [counter, setCount] = useState(quantity);
  const dispatch = useDispatch();
  const products = JSON.parse(localStorage.getItem("cart"));
  const totalofTotals = [];
  products.map((item) => {
    if (item.discount === null || item.discount == 0) {
      totalofTotals.push(item.price * item.quantity);
    } else {
      const myDiscount = item.discount / 100;
      const calcuteDiscount = item.price * myDiscount;
      const totalPrice = item.price - calcuteDiscount;
      const summation = totalPrice * item.quantity;
      totalofTotals.push(summation);
    }
  });
  const initialValue = 0;
  const sumWithInitial = totalofTotals.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue
  );
  useEffect(() => {
    dispatch(sumTotal(sumWithInitial));
  }, [sumWithInitial]);

  const handlePlus = () => {
    setCount(counter + 1);
    dispatch(changeQuantityPlus({ id, sizeId, colorId }));
  };
  const handleMinus = () => {
    if (counter <= 1) return;
    setCount(counter - 1);
    // dispatch(minusTotal(price));
    dispatch(changeQuantityMinus({ id, sizeId, colorId }));
  };
  useEffect(() => {
    if (discount === null || discount == 0) {
      setTotal(counter * price);
    } else {
      const myDiscount = discount / 100;
      const calcuteDiscount = price * myDiscount;
      const totalPrice = price - calcuteDiscount;
      const summation = totalPrice * counter;
      setTotal(summation);
    }
  }, [counter]);

  return (
    <div className="item">
      {console.log(id)}
      <div className="info">
        <div className="image-container">
          <img src={image} alt="" />
        </div>
      </div>
      <div className="cart-details">
        <span className="cart-title">{titleNameEn}</span>
        <div className="options">
          <span
            onClick={() => {
              handleMinus(index);
            }}
            className="minus"
          >
            -
          </span>
          <span className="value">{counter}</span>
          <span
            onClick={() => {
              handlePlus(index);
            }}
            className="plus"
          >
            +
          </span>
        </div>

        <span>{total} EGP</span>
      </div>

      <span
        className="delete"
        onClick={() => {
          dispatch(removeCart({ id: id, sizeId, colorId, total: total }));
        }}
      >
        <img src={deleteImg} alt="" />
      </span>
    </div>
  );
};

export default CartItem;
