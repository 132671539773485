import Logo from "../../assets/logo-2.png";
import Login from "./Login";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const SignIn = () => {
  const navigate = useNavigate();
  return (
    <section className="sign-in">
      <div className="container">
        <div className="row ">
          <div className="col describe">
            <img src={Logo} alt="" />
            <h2>login</h2>
            <p>
              Please enter the following data to <br /> login & if you don't
              Have Account <br />
              sign up
            </p>
            <Link to={"/register"} className="login-btn">
              Sign up
            </Link>
          </div>
          <div className="col">
            <Login navigate={navigate} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
