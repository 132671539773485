import "./item.scss";
import star from "../assets/star.png";
import heart from "../assets/heart.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const priceCheck = (discount, price) => {
  if (discount === null || discount == 0) {
    return <span className="new">{price} EGP</span>;
  } else {
    const myDiscount = discount / 100;
    const calcuteDiscount = price * myDiscount;
    const totalPrice = price - calcuteDiscount;
    return (
      <>
        <span className="old">{price}EGP</span>
        <span className="new">{totalPrice} EGP</span>
      </>
    );
  }
};
const rateCheck = (rate) => {
  if (rate === null) {
    return null;
  } else {
    return (
      <>
        <span>{rate}</span>
        <img src={star} alt="" />
      </>
    );
  }
};
const token = localStorage.getItem("token");
const headers = { Authorization: `Bearer ${token}` };
const createWishList = (id) => {
  axios.post(
    `https://beneshty.oncreation.net/api/wishlist/add/${id}`,
    {},
    { headers }
  );
};
const Item = ({ image, title, price, discount, rate, id }) => {
  let navigate = useNavigate();
  const handleClick = (e) => {
    console.log(e.target.className);
    if (e.target.className == "description") {
      navigate(`/product-details/${id}`);
    }
  };
  const handleOnClick = () => {
    navigate(`/product-details/${id}`);
  };
  return (
    <div onClick={handleClick} className="item-card">
      <img className="card-item" src={image} alt="" />
      <div className="description">
        <div className="tittle">
          <h5>{title}</h5>
          {rateCheck(rate)}
        </div>
        <div className="price">
          <p>
            {priceCheck(discount, price)}
            {/* <span className="old">{price}EGP</span>
            <span className="new">200 EGP</span> */}
          </p>
        </div>
        <div className="cart">
          <button className="btn-cart" onClick={handleOnClick}>
            ADD TO CART
          </button>
          <img
            src={heart}
            alt=""
            onClick={() => {
              createWishList(id);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Item;
