import ForgetBox from "./forgetBox";
import Logo from "../../assets/logo-2.png";
import forget from "../../assets/forget.png";
import "./forgetpassword.scss";
const ForgetPassword = () => {
  return (
    <section className="forget-password">
      <div className="container">
        <div className="row ">
          <div className="col describe">
            <img src={Logo} alt="" />
            <h2>forget password</h2>
            <p>
              Enter phone number to recover <br /> password
            </p>
          </div>
          <div className="col forget-box">
            <div className="image-box">
              <img src={forget} alt="" />
              <ForgetBox />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
