import congratulation from "../../assets/congratulations.png";
import higher from "../../assets/higher.png";
import "./index.scss";
const Congratulation = () => {
  return (
    <section className="congratulation">
      <div className="container">
        <p className="head-line">Shipping cart</p>
        <img className="higher" src={higher} alt="" />
        <p className="head-line">Delivery Address</p>
        <img className="higher" src={higher} alt="" />
        <p className="second-title">Checkout</p>
        <div className="col-lg-12 d-flex justify-content-center text-center">
          <div>
            <img src={congratulation} alt="" />
            <h2 style={{ color: "#601c7e" }}>Congratulation</h2>
            <p style={{ color: "#9F9F9F" }}>
              Your order has been sent <br /> successfully
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Congratulation;
