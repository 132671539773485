import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import BestSellerItem from "./../../components/BestSellerItem";
import arrow from "../../assets/arrow.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiLink } from "../../api";
import { useSelector } from "react-redux";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
const BestSeller = () => {
  const [data, setData] = useState([]);
  const globalLanguage = useSelector((state) => state.language.english);
  const [langComp, setLangComp] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animate = useAnimation();
  useEffect(() => {
    if (inView) {
      animate.start({
        x: 0,
        transition: {
          type: "spring",
          duration: "2",
          bounce: "0.3",
          delay: "0.2",
        },
      });
    }
    if (!inView) {
      animate.start({
        x: "-100vw",
      });
    }
  }, [inView]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${apiLink}/api/product/bestseller`,
    }).then((response) => {
      setData(response.data);
    });
  }, []);
  const filteredArray = data.filter((item, index) => {
    return index < 6;
  });
  console.log("filtered array", filteredArray);
  useEffect(() => {
    setLangComp(!langComp);
  }, [globalLanguage]);
  return (
    <section ref={ref} className="bestseller">
      <div className="container">
        <div className="top">
          {globalLanguage ? <h1>BEST SELLER</h1> : <h1>افضل منتج</h1>}
          <Link to={"/bestseller"}>
            VIEW ALL <img src={arrow} alt="" />
          </Link>
        </div>
        <motion.div className=" max" animate={animate}>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            autoplay={{ delay: 2500 }}
            // dir="rtl"
            // dir={`${globalLanguage ? "" : "rtl"}`}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper max"
          >
            {filteredArray.length > 0
              ? filteredArray.map((item) => {
                  return (
                    <SwiperSlide key={item.id}>
                      <BestSellerItem
                        discount={item.discount}
                        price={item.price}
                        title={item.en_name}
                        image={
                          item.color[0].image.length === 0
                            ? ""
                            : item.color[0].image[0].image
                        }
                        rate={item.rate}
                        id={item.id}
                      />
                    </SwiperSlide>
                  );
                })
              : ""}
          </Swiper>
        </motion.div>

        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          autoplay={{ delay: 2500 }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper small"
        >
          {filteredArray.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <BestSellerItem
                  image={
                  item.color.length !== 0 ?
                    item.color[0].image.length === 0
                      ? ""
                      : item.color[0].image[0].image
                      : ''
                  }
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default BestSeller;
